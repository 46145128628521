import React,{ useState, useEffect } from 'react';
import { Header } from '../components';
import { Button, TextField, TableBody, TableRow, TableCell, Autocomplete, FormControlLabel, FormGroup, Checkbox, CircularProgress, Grid, Alert } from '@mui/material';
import { BiUpload, BiCheck } from 'react-icons/bi';
import useTable from "../components/useTable";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import locale from 'date-fns/locale/sr-Latn';
import { format, isBefore, isAfter } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import { getOptionsTrainings } from '../services/trainingService'
import * as planInsertService from '../services/planInsertService'
import { RiNurseFill } from 'react-icons/ri';
import Errortable from '../components/Errortable';
import ImportTable from '../components/ImportTable';
import LoadingButton from '@mui/lab/LoadingButton';
import { url } from '../data/dummy';

const PlanInsert = () => {
    
    const [file, setFile] = useState(null)
    const [records, setRecords] = useState(null)
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    const [openPopup, setOpenPopup] = useState(false)
    
    const [errors, setErrors] = useState(false)
    const [otherErrors, setOtherErrors] = useState(false)
    const [otherErrorsMsg, setOtherErrorsMsg] = useState(false)
    const [training, setTraining] = useState(false)
    const [headCells, setHeadCells] = useState([])
    const [success, setSuccess] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)


    const handleChange = (event) => {
        setTraining(false)
        setFile(event.target.files[0])
        event.target.value = null;
        
    }
    
    useEffect(()=>{
        if(file != null){
            setErrors(false)
            setOtherErrors(false)
            setTraining(false)
            planInsertService.uploadPlan(file, setRecords, setOtherErrors, setOtherErrorsMsg, setErrors, setTraining, setUploadLoading)
        }
    },[file])

    useEffect(()=>{
        if(training){
            
        }
    },[training])

    const confirmInsert = (event) => {
        
        let nepopunjeni =  records.filter(item => item.value == null)
        if(nepopunjeni.length){
            alert('Nisu popunjeni svi treninzi!')
        }else{
            let final = records.map(item => {
               return {
                    acStore:item.acStore,
                    acWorkerFullName:item.acWorkerFullName,                
                    adDateEnd:item.adDateEnd,
                    adDateStart:item.adDateStart,
                    anId:item.anId,
                    userID:item.userID,
                    trainingID:item.value.id
                }
            })
            
            fetch(url+'/importplan.php', { // Your POST endpoint
                method: 'POST',
                body:  JSON.stringify(final)// This is your file object
            }).then(
                response => response.text() // if the response is a JSON object
            ).then(
                success => {
                    setTraining(false)
                    setSuccess(true)
                } // Handle the success response object
            ).catch(
                error => console.log(error) // Handle the error response object
            );
           
        }
    }

    useEffect(()=>{
        
    },[records])
      
      // const classes = useStyles();
      
      
    return (
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Header category="Stranica" title="Unos plana rada" />
            <div className="mb-5">
            <LoadingButton variant="outlined" loading={uploadLoading} color="secondary" loadingPosition="start" component="label" startIcon={<BiUpload />}>
                Uvezi plan
                <input hidden accept="*" multiple onChange = {handleChange} type="file" />
            </LoadingButton>
            { training  && <><LoadingButton variant="outlined" color="secondary" component="label" sx={{marginLeft:1}} onClick = {confirmInsert} startIcon={<BiCheck />}>
                {/* <CircularProgress size={20} color="secondary" /> */}
                Potvrdi unos
            </LoadingButton>
            {/* <p>{file.name}</p> */}
            
            </>
            }
            </div>
            {success && <div className="mb-5"><Alert severity="success">Uspešno unešen plan rada!</Alert></div>}
            {otherErrors && <div className="mb-5"><Alert severity="error">{otherErrorsMsg}</Alert></div>}
            {errors && <><Errortable recordsprop={records}/></>
            }
      
            { training && records.length > 0 && <>
            
                    <ImportTable recordsprop={records} setRecordprops={setRecords} />
                </>
            }

            {training && records.length == 0 && <div className="mb-5"><Alert severity="success">Plan je uspešno učitan, potvrdite unos!</Alert></div>}
      
        </div>
    )
}

export default PlanInsert