import React, { useEffect } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { MdKeyboardArrowDown } from 'react-icons/md';

import {  UserProfile } from '.'
import { useStateContext  } from '../contexts/ContextProvider';
import useAuth from '../hooks/useAuth';

const NavbarButton = ({ title, customFunc, icon, color, dotColor}) => (
  
    <button
      type="button"
      onClick={customFunc}
      style={{color}}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span style={{background: dotColor}}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      ></span>
      {icon}
    </button>
  
)

const Navbar = () => {
  const { auth } = useAuth();
  const { activeMenu, setActiveMenu, isClicked, setIsClicked, handleClick,screenSize, setScreenSize } = useStateContext();

  useEffect(()=>{
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if(screenSize <= 900){
      setActiveMenu(false);
    }else{
      setActiveMenu(true);
    }

  },[screenSize])
  return (
    <div
      className='flex justify-between p-2 md:mx-6 relative'
    >
      <NavbarButton
        title="Menu"
        customFunc={() => setActiveMenu(
          (prevActiveMenu) => !prevActiveMenu
          )}
        color="#ec2028"
        icon={<AiOutlineMenu />}
      />
      <div className='flex'>
        
        <div 
          className='flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg'
          // onClick={() => handleClick('userProfile')}
        >
          {/* <img src={} className="rounded-full w-8 h-8" /> */}
          <p>
            {/* <span className='text-gray-400 text-14'>Hi, </span> {' '} */}
            <span className='text-gray-400 font-bold ml-1 text-14'>{auth.user}</span>
          </p>
          {/* <MdKeyboardArrowDown className='text-gray-400 text-14' /> */}
        </div>
      
        {isClicked.userProfile && <UserProfile />}
      </div>
    </div>
  )
}

export default Navbar