import React from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { TbPackgeImport } from 'react-icons/tb';
import {GiTeacher} from 'react-icons/gi';
import { IoMdContacts } from 'react-icons/io';
import { MdSchool, MdLogout } from 'react-icons/md';
import {MdOutlineWork} from 'react-icons/md'
import {RiNodeTree} from 'react-icons/ri'
import {TbCalendarMinus, TbCalendarPlus,TbCalendarEvent} from 'react-icons/tb'

// export const url = "http://localhost/jasmin";
export const url = "";
// export const url = "http://192.168.2.240/";


export const links = [
  {
    title: 'Apps',
    links: [
      {
        name: 'kalendar',
        icon: <TbCalendarEvent />,
        auth: []
      }
    ],
  },
  {
    title: 'Zahtevi',
    links: [
      {
        name: 'prijave',  
        icon: <MdOutlineWork />,
        auth:  ["user"]

      },
      // {
      //   name: 'odsustvo',
      //   icon: <AiFillHome />,
      //   auth:  ["user"]
      // },
      
    ]
  },
  {
    title: 'Stranice',
    links: [
      
      {
        name: 'zaposleni',
        icon: <IoMdContacts />,
        auth:  ["user","admin"]
      },
      {
        name: 'treneri',
        icon: <GiTeacher />,
        auth:  ["user","admin"]
      },
      {
        name: 'dobavljači',
        link: 'dobavljaci',
        icon: <TbPackgeImport />,
        auth:  ["user","admin"]
      },
      {
        name: 'treninzi',
        icon: <MdSchool />,
        // auth:  ["user","admin"]
        auth:  []
      },
      {
        name: 'tipovi i podtipovi',
        link: 'tipovipodtipovi',
        icon: <RiNodeTree />,
        auth:  ["user","admin"]
      },
      {
        name: 'brisanje plana',
        link: 'brisanjeplana',
        icon: <TbCalendarMinus />,
        auth:  ["user","admin"]
      },
      {
        name: 'unos plana',
        link: 'unosplana',
        icon: <TbCalendarPlus />,
        auth:  ["user","admin"],
        mobile: false
      },
    ],
  },
  {
    title: 'Nalog',
    links: [
      {
        name: 'odjava',
        icon: <MdLogout />,
        auth:  []

      },
    ]
  }
];
