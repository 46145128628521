import React, { useState } from 'react'
import {FormContainer, TextFieldElement, TextareaAutosizeElement, TimePickerElement, useForm} from 'react-hook-form-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import locale from 'date-fns/locale/sr-Latn';
import { format } from 'date-fns';


const AttendanceEditForm = (props) => {
    const { addOrEdit, recordForEdit } = props
    const [success, setSuccess] = useState(false)
    const [successMsg, setSuccessMsg] = useState('')
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
  
    const defaultValues = (recordForEdit != null ? {
        type:'admin',
        id: recordForEdit.anID,
        workerId: recordForEdit.acWorkerID,
        note: '',
        time: recordForEdit.adDateTimeStamp,
    } : {
        type:'admin',
        id: '',
        workerId: '',
        note: '',
        time: '',
    })
  
    const formContext = useForm({
      defaultValues: defaultValues
    })
  
  
    const {handleSubmit} = formContext
  
    const confirmCheckInOut = (data) => {
      let submitdata = {...data }
      // submitdata.store = submitdata.store.id
      // submitdata.checkType = submitdata.checkType.label
      // submitdata.type = submitdata.type.label
      submitdata.time = format(new Date(data.time), 'HH:mm')
      addOrEdit(submitdata, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading)
    }
    
    return (
      <FormContainer
        formContext={formContext}
        handleSubmit={handleSubmit(confirmCheckInOut)}
      >
        <TextFieldElement color="secondary" fullWidth margin="dense" sx={{display:'none'}} label="id" name="id"/>
        <TextFieldElement color="secondary" fullWidth margin="dense" sx={{display:'none'}} label="ID zaposlenog" name="workerId"/>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <TimePickerElement
            fullWidth
            label="Vreme dolaska/odlaska"
            name="time"
            required
            validation={{
                required: 'Obavezno je odabrati vreme!'
            }}
            inputProps={{
              required: true,
              fullWidth: true,
              margin:"dense",
              color:"secondary"
          }}
        />
        </LocalizationProvider>
        <TextareaAutosizeElement color="secondary" fullWidth margin="dense" name="note" label="Napomena" resizeStyle="none" rows="4"/>
        <br />
        <LoadingButton loading={submitLoading} type={'submit'} color={'primary'}>
          Potvrdi
        </LoadingButton>
        {success && <div className="mb-5"><Alert severity="success">{successMsg}</Alert></div>}
        {error && <div className="mb-5"><Alert severity="error">{errorMsg}</Alert></div>}
      </FormContainer>
    )
}

export default AttendanceEditForm