import React, { useState } from 'react'
import {FormContainer, TextFieldElement, TextareaAutosizeElement, AutocompleteElement, useForm} from 'react-hook-form-mui';
import { Button } from '@mui/material';
import * as calendarService from "../../services/calendarService";
import useAuth from '../../hooks/useAuth'
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';

const CheckInOutForm = (props) => {
  const { auth } = useAuth();
  const { addOrEdit } = props

  const [optionsStore, setOptionsStore] = useState([])
  const [optionsAttendType, setOptionsAttendType] = useState([])
  const [success, setSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const defaultValues =  {
    username: 'PIN',
    type: {label:'Prijava'},
    checkType: null,
    pass: '',
    store: {id:auth.storeId, label:auth.store},
    note: ''
  }

  const formContext = useForm({
    defaultValues: defaultValues
  })


  const {handleSubmit} = formContext

  
  const onInputStoreChange = (e) => {
    calendarService.getOptionsStoreId(setOptionsStore, e.target.value)
  }
  const onInputStoreOpen = (e) => {
    calendarService.getOptionsStoreId(setOptionsStore, '')
  }

  const onInputAttendChange = (e) => {
    calendarService.getOptionsAttendType(setOptionsAttendType, e.target.value)
  }
  const onInputAttendOpen = (e) => {
    calendarService.getOptionsAttendType(setOptionsAttendType, '')
  }
  
  const confirmCheckInOut = (data) => {
    let submitdata = {...data }
    submitdata.store = submitdata.store.id
    submitdata.checkType = submitdata.checkType.label
    submitdata.type = submitdata.type.label
    addOrEdit(submitdata, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading)
  }
  
  return (
    <FormContainer
      formContext={formContext}
      handleSubmit={handleSubmit(confirmCheckInOut)}
    >
      <TextFieldElement color="secondary" fullWidth margin="dense" sx={{display:'none'}} label="Username" name="username"/>
      <AutocompleteElement
            name="type"
            label="Tip zahteva"
            required
            textFieldProps={{
                required: true,
                fullWidth: true,
                margin:"dense",
                validation:{
                    required: {
                        value: true,
                        message: 'aaa'
                    }
                },
                color:"secondary"
            }}
            autocompleteProps={{
                required:true,
                validation:{
                    required: {
                    value: true,
                    message: ''
                    }
                }
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={[{label:"Prijava"}, {label:"Odjava"}]}
            // onInputChange={onInputCoachChange}
            
            />
        <AutocompleteElement
            name="checkType"
            label="Prijava/Odjava na"
            required
            textFieldProps={{
                required: true,
                fullWidth: true,
                margin:"dense",
                validation:{
                    required: {
                        value: true,
                        message: 'aaa'
                    }
                },
                color:"secondary",
                onChange:(e)=>{onInputAttendChange(e)}
            }}
            autocompleteProps={{
                required:true,
                validation:{
                    required: {
                    value: true,
                    message: ''
                    }
                },
                onOpen:(e)=>{onInputAttendOpen()}
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={optionsAttendType}
            // onInputChange={onInputCoachChange}
            
            />
      <AutocompleteElement
            name="store"
            label="Radnja"
            required
            textFieldProps={{
                required: true,
                fullWidth: true,
                margin:"dense",
                color:"secondary",
                validation:{
                    required: {
                        value: true,
                        message: 'aaa'
                    }
                },
                onChange:(e)=>{onInputStoreChange(e)}
            }}
            autocompleteProps={{
                required:true,
                validation:{
                    required: {
                    value: true,
                    message: ''
                    }
                },
                onOpen:(e)=>{onInputStoreOpen()}
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={optionsStore}
            
            
            />
        <TextFieldElement color="secondary" fullWidth margin="dense" type="number" label="PIN" required name="pass"/>
      <TextareaAutosizeElement color="secondary" fullWidth margin="dense" name="note" label="Napomena" resizeStyle="none" rows="4"/>
      <br />
      <LoadingButton loading={submitLoading}  type={'submit'} color={'primary'}>
        Potvrdi
      </LoadingButton>
      {success && <div className="mb-5"><Alert severity="success">{successMsg}</Alert></div>}
      {error && <div className="mb-5"><Alert severity="error">{errorMsg}</Alert></div>}
    </FormContainer>
  )
}

export default CheckInOutForm