import React, { useState, useEffect } from 'react';

import { TableBody, TableRow, TableCell, Button, Toolbar, InputAdornment, TextField, Grid } from '@mui/material';
import { makeStyles } from '@material-ui/styles'
import useTable from "../components/useTable";
import * as checkInOutService from "../services/checkInOutService";
import { Search } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import Popup from "../components/Popup";
import ResponsiveDialogConfirm from "../components/ResponsiveDialogConfirm";
import ResponsiveDialog from "../components/ResponsiveDialog";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import useAuth from '../hooks/useAuth';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import locale from 'date-fns/locale/sr-Latn';
import { format, isBefore, isAfter, isEqual } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';

import { Header } from '../components';
import AttendanceEditForm from '../components/Forms/AttendanceEditForm';

const CheckInOut = () => {

  const {auth} = useAuth();
const headCells = [
  { id: 'acWorkerID', label: 'Zaposleni' },
  { id: 'acLocationName', label: 'Radnja' },
  { id: 'acAttendanceType', label: 'Tip' },
  { id: 'adDateTimeStamp', label: 'Vreme', disableSorting: true },
  { id: 'actions', label: 'Akcije', disableSorting: true }
]

const [formTitle, setFormTitle] = useState('Promena vremena dolaska/odlaska')
const [searchDate, setSearchDate] = useState(null)
const [recordForEdit, setRecordForEdit] = useState(null)
const [records, setRecords] = useState([])
const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
const [openPopup, setOpenPopup] = useState(false)
const [openPopupDel, setOpenPopupDel] = useState(false)
const [storeId, setStoreId] = useState((auth.role.toLowerCase() == 'manager' ? 0 : auth.storeId))



useEffect(() => {
  checkInOutService.getAllCheckInOuts(setRecords, storeId)
}, []);

const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
} = useTable(records, headCells, filterFn);

useEffect(()=>{
    setFilterFn({
        fn: items => {
            if (searchDate == null)
                return items;
            else{
                return items.filter(x => {
                    
                    return format(x.adDateTimeStamp,'yyyy-MM-dd') ==  format(searchDate,'yyyy-MM-dd') })
            }
            
        }
    })
  },[searchDate])

const addOrEdit = (attendance, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading) => {
    
    setSuccess(false)
    setError(false)
    checkInOutService.updateCheckInOut(attendance, setRecords, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading, setRecordForEdit, setOpenPopup) 
    // setRecordForEdit(null)
    // setOpenPopup(false)
  }
  
  const openInPopup = item => {
    setFormTitle('Promena vremena dolaska/odlaska')
    setRecordForEdit(item)
    setOpenPopup(true)
  }
  const openInPopupDel = item => {
    
    setRecordForEdit(item)
    setOpenPopupDel(true)
  }
  
  const deleteItem = () => {
    
    checkInOutService.deleteCheckInOut(recordForEdit, setRecords) 
    setRecordForEdit(null)
    setOpenPopupDel(false)
  }
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Zahtevi" title="Prijave/Odjave" />
      {/* <div className='mb-5'>
        <Button         
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => { setFormTitle('Novi dobavljač'); setOpenPopup(true); setRecordForEdit(null); }}
        >Novi dobavljač</Button>
      </div> */}
      <Grid container spacing={2}  sx={{alignItems:'center'}}>
                <Grid item xs={12} md={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                    <DatePicker label="Datum" value={searchDate} componentsProps={{
                            actionBar: {
                            actions: ['clear'],
                          },
                        }} onChange={(val)=>{setSearchDate(val)}}
                        renderInput={(props) => <TextField fullWidth color="secondary" onKeyDown={onKeyDown} {...props} />}
                    />
                    </LocalizationProvider>
                </Grid>
                  <Grid item xs={0} md={10}></Grid>
                </Grid>
                <div className='mb-5'></div>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item.anID}>
                                    <TableCell>{item.acWorkerID}</TableCell>
                                    <TableCell>{item.acLocationName}</TableCell>
                                    <TableCell>{item.acAttendanceType}</TableCell>
                                    <TableCell>{format(item.adDateTimeStamp,'dd.MM.yyyy')} {format(item.adDateTimeStamp,'HH:mm')}</TableCell>
                                    <TableCell style={{textAlign:'right', padding:0}}>
                                        <Button
                                            color="primary"
                                            onClick={() => { openInPopup(item) }}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </Button>
                                        <Button
                                            color="secondary"
                                            onClick={() => { openInPopupDel(item)}}>
                                            <CloseIcon fontSize="small" />
                                        </Button>
                                    </TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                </TblContainer>
                <TblPagination />
                <ResponsiveDialog
                    title={formTitle}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >
                  <AttendanceEditForm 
                  recordForEdit={recordForEdit}
                  addOrEdit={addOrEdit} 
                  />
                    
                </ResponsiveDialog>
                <ResponsiveDialogConfirm
                  title={'Da li ste sigurni da želite da obrišete stavku?'}
                  open={openPopupDel}
                  setOpen={setOpenPopupDel}
                  handleConfirm={deleteItem}
                />

    </div>
  );
};
export default CheckInOut;

