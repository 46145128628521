import { format } from 'date-fns';
import { url } from '../data/dummy';
export async function getAllCheckInOuts(setRecords, value) {
    
    fetch(url+'/checkin-checkout.php?type=list&value='+value, { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            let results = success.map(item => {
                item.adDateTimeStamp = new Date(item.adDateTimeStamp)
                return item
            })
            
            setRecords(results)
        } // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}






export async function updateCheckInOut(data, setRecords, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading, setRecordForEdit, setOpenPopup) {
    
    setSubmitLoading(true)
    fetch(url+'/checkin-checkout.php', { // Your POST endpoint
        method: 'POST',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            if(success[0].isOk == 0){
                setErrorMsg(success[0].acMessage)
                setError(true)
                
            }else{
                
                setSuccessMsg(success[0].acMessage)
                setSuccess(true)
                setRecords(prev => {
                    let newrec = prev.map(item=>item)
                    newrec.forEach(item => {
                        if(item.anID == data.id){
                            
                            let datum = format(item.adDateTimeStamp,'yyyy-MM-dd')
                            item.adDateTimeStamp = new Date(datum+' '+data.time)
                            item.acNote = data.note
                        }
                    })
                    return newrec
                })
                setTimeout(() => {
                    setRecordForEdit(null)
                    setOpenPopup(false)
                },2000)
            }
            setSubmitLoading(false)
        } // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}



export async function deleteCheckInOut(data, setRecords) {
    
    fetch(url+'/checkin-checkout.php', { // Your POST endpoint
        method: 'DELETE',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            if(success[0].isOk == 0){
                alert(success[0].acMessage)
            }else{
                
                setRecords(prev => {
                
                    return prev.filter(x => x.anID != data.anID)
                })
            }
        } // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}
