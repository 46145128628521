
export async function getAllVacations(setRecords) {
    
    fetch('http://localhost/test/vacations.php?type=list', { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            let results = success.map(item => {
                item.adDateTimeStamp = new Date(item.adDateTimeStamp)
                return item
            })
            setRecords(results)
        } // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}






export async function insertUpdateVacation(data) {
    console.log('insert',data)
    
    fetch('http://localhost/test/vacations.php', { // Your POST endpoint
        method: 'POST',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => console.log(success) // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}



export async function deleteVacation(data, setRecords) {
    console.log('insert',data)
    
    fetch('http://localhost/test/checkin-checkout.php', { // Your POST endpoint
        method: 'DELETE',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            if(success[0].isOk == 0){
                alert(success[0].acMessage)
            }else{
                console.log(data)
                setRecords(prev => {
                    console.log(prev)
                    return prev.filter(x => x.anID != data.anID)
                })
            }
        } // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}
