import React, {useState} from 'react'
import {FormContainer, TextFieldElement, AutocompleteElement, useForm} from 'react-hook-form-mui';
import {getOptionsStore} from "../../services/calendarService";
import {getOptionsJob} from "../../services/employeeService";
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';

const EmployeeForm = (props) => {
  const { addOrEdit, recordForEdit } = props
  const [optionsStore, setOptionsStore] = useState([]);
  const [optionsJob, setOptionsJob] = useState([]);
  const [success, setSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const defaultValues = (recordForEdit != null ? {
    type:'UPDATE',
    id: recordForEdit.acWorkerID,
    name: recordForEdit.acName,
    lastName: recordForEdit.acSurname,
    job: {label: recordForEdit.acJob},
    store: {label:recordForEdit.acStoreFullName,id:recordForEdit.acStore},
    phone: recordForEdit.acPhone,
    email: recordForEdit.acEmail,
  } : {
    type:'INSERT',
    id:'',
    name: '',
    lastName: '',
    job: '',
    store: '',
    phone: '',
    email: '',
  })
  
  const formContext = useForm({
    defaultValues: defaultValues
  })


  const {handleSubmit} = formContext

  const saveEmployee = (data) => {
    let submitdata = {...data }
    submitdata.store = submitdata.store.id
    submitdata.job = submitdata.job.label
    addOrEdit(submitdata, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading)
  }
  
  const onInputStoreChange = (e) => {
    getOptionsStore(setOptionsStore, e.target.value)
  }
  const onInputStoreOpen = (e) => {
    getOptionsStore(setOptionsStore, '')
  }

  const onInputJobChange = (e) => {
    getOptionsJob(setOptionsJob, e.target.value)
  }
  const onInputJobOpen = (e) => {
    getOptionsJob(setOptionsJob, '')
  }

  return (
    <FormContainer
      formContext={formContext}
      handleSubmit={handleSubmit(saveEmployee)}
    >
      
      <TextFieldElement fullWidth margin="dense" name="type" label="Tip" sx={{display:'none'}}/>
      <TextFieldElement fullWidth margin="dense" name="id" label="ID zaposlenog" required validation={{
              required: {
                value: true,
                message: ''
              }
            }}/>
      <TextFieldElement fullWidth margin="dense" name="name" label="Ime" required validation={{
              required: {
                value: true,
                message: ''
              }
            }}/>
      <TextFieldElement fullWidth margin="dense" name="lastName" label="Prezime" required validation={{
              required: {
                value: true,
                message: ''
              }
            }}/>
      <AutocompleteElement
            name="store"
            label="Radnja"
            required
            color="secondary"
            textFieldProps={{
                color:"secondary",
                required: true,
                fullWidth: true,
                margin:"dense",
                validation:{
                    required: {
                        value: true,
                        message: 'aaa'
                    }
                },
                onChange:(e)=>{onInputStoreChange(e)}
            }}
            autocompleteProps={{
                required:true,
                validation:{
                    required: {
                    value: true,
                    message: ''
                    }
                },
                onOpen:(e)=>{onInputStoreOpen()}
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={optionsStore}
            // onInputChange={onInputCoachChange}
            
      />
      <AutocompleteElement
            name="job"
            label="Radno mesto"
            required
            color="secondary"
            textFieldProps={{
                color:"secondary",
                required: true,
                fullWidth: true,
                margin:"dense",
                validation:{
                    required: {
                        value: true,
                        message: 'aaa'
                    }
                },
                onChange:(e)=>{onInputJobChange(e)}
            }}
            autocompleteProps={{
                required:true,
                validation:{
                    required: {
                    value: true,
                    message: ''
                    }
                },
                onOpen:(e)=>{onInputJobOpen()}
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={optionsJob}
            // onInputChange={onInputCoachChange}
            
      />
      <TextFieldElement fullWidth color="secondary" margin="dense" name="phone" label="Telefon" required validation={{
          required: {
            value: true,
            message: ''
          }
        }}/>
      <TextFieldElement color="secondary" fullWidth margin="dense" name="email" label="Email"
        type={'email'} required validation={{
          required: {
            value: true,
            message: ''
          }
        }}/>
      <br />
      <LoadingButton loading={submitLoading} type={'submit'} color={'primary'}>
        Potvrdi
      </LoadingButton>
      {success && <div className="mb-5"><Alert severity="success">{successMsg}</Alert></div>}
      {error && <div className="mb-5"><Alert severity="error">{errorMsg}</Alert></div>}
    </FormContainer>
  )
}

export default EmployeeForm