import React, {useState} from 'react'
import * as calendarService from "../services/calendarService";
import {getOptionsJob} from "../services/employeeService";
import * as typeService from '../services/typeService'
import useAuth from '../hooks/useAuth';
import { useStateContext } from '../contexts/ContextProvider';
import { Button, TextField,  Autocomplete,  Grid, IconButton } from '@mui/material';
import { AiFillFilter, AiOutlineFilter } from 'react-icons/ai'


const CalendarFilters = ({employee, setEmployee, store, setStore, storeName, setStoreName, engagementType, setEngagementType, setOpenPopupCheckInOut, jobFilt, setJobFilt,engagementSubType, setEngagementSubType }) => {
    const { screenSize,  } = useStateContext();
  
    const { auth } = useAuth();
    
    const [optionsEmployee, setOptionsEmployee] = useState([])
    const [optionsStore, setOptionsStore] = useState([])
    const [optionsType, setOptionsType] = useState([])
    const [optionsSubType, setOptionsSubType] = useState([])
    const [optionsJob, setOptionsJob] = useState([])
    
    const [filterOpened,setFilterOpened] = useState(false)
    
    const filtersx = filterOpened ? {}: {display:'none'} 
    
    const onInputEmployeeChange = (e) => {
        calendarService.getOptionsEmployee(setOptionsEmployee, e.target.value, storeName, jobFilt)
    }

    const onInputEmployeeOpen = (e) => {
      calendarService.getOptionsEmployee(setOptionsEmployee, '', storeName, jobFilt)
    }
    const onInputStoreChange = (e) => {
      calendarService.getOptionsStoreId(setOptionsStore, e.target.value)
    }
    const onInputStoreOpen = (e) => {
      calendarService.getOptionsStoreId(setOptionsStore, '')
    }
    
    
  const onInputTypeChange = (e) => {
    typeService.getOptionsType(setOptionsType, e.target.value)
  }
  const onInputTypeOpen = (e) => {
    typeService.getOptionsType(setOptionsType, '')
  }

  
  const onInputSubTypeChange = (e) => {
    typeService.getOptionsSubType(setOptionsSubType, e.target.value, engagementType,'')
  }
  const onInputSubTypeOpen = (e) => {
    typeService.getOptionsSubType(setOptionsSubType, '', engagementType,'')
  }

    const onInputJobChange = (e) => {
      getOptionsJob(setOptionsJob, e.target.value)
    }
    const onInputJobOpen = (e) => {
      getOptionsJob(setOptionsJob, '')
    }

    let showRadnikFilt = {}
    if(auth.role.toLowerCase() == "user"){
      showRadnikFilt = {display:'none'}
    }
    
    let showStoreFilt = {}
    if(auth.role.toLowerCase() != "manager"){
      showStoreFilt = {display:'none'}
    }
    let emptyGridColumns 
    if(auth.role.toLowerCase() == "user"){
      emptyGridColumns = 10
    }else if(auth.role.toLowerCase() == "admin"){
      emptyGridColumns = 6
    }else if(auth.role.toLowerCase() == "manager"){
      emptyGridColumns = 0
    }
    
  return (
    <>
    {screenSize > 900  && <Grid container spacing={2}  sx={{alignItems:'center'}}>
            <Grid sx = {showRadnikFilt} item xs={12} md={2}>
                <Autocomplete
                    
                    disablePortal
                    onChange={(event, newValue) => {
                        if(newValue == null){
                          setEmployee('')
                        }else{
                          setEmployee(newValue.id)
                        }
                      }}
                    options={optionsEmployee}
                    defaultValue={{ label: employee, id: employee }}
                    onOpen={(e)=>{onInputEmployeeOpen()}}
                    // sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} color="secondary" onChange={(e)=>{onInputEmployeeChange(e)}} label="Radnik" />}
                    />
                
            </Grid>
            <Grid item sx = {showStoreFilt} xs={12} md={2}>
                    <Autocomplete
                        
                        disablePortal
                        onChange={(event, newValue) => {
                            if(newValue == null){
                              setStore(0)
                              setStoreName('')
                            }else{
                            setStore(newValue.id)
                            setStoreName(newValue.label)
                            }
                          }}
                        options={optionsStore}
                        defaultValue={{ label: storeName, id: store }}
                        // textFieldProps={{
                        //     onChange:(e)=>{onInputStoreChange(e)}
                        // }}
                        onOpen={(e)=>{onInputStoreOpen()}}
                        // sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} color="secondary" onChange={(e)=>{onInputStoreChange(e)}} label="Radnja" />}
                        />
                    
                </Grid>
                <Grid sx={showStoreFilt} item xs={12} md={2}>
                    <Autocomplete
                        // sx = {showStoreFilt}
                        disablePortal
                        onChange={(event, newValue) => {
                          console.log(newValue)
                            if(newValue == null){
                              setEngagementType('')
                            }else{
                              setEngagementType(newValue.label)
                            }
                          }}
                          options={optionsType}
                        // textFieldProps={{
                        //     onChange:(e)=>{onInputStoreChange(e)}
                        // }}
                        onOpen={(e)=>{onInputTypeOpen()}}
                        // sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} color="secondary" onChange={(e)=>{onInputTypeChange(e)}} label="Tip" />}
                        />
                    
                </Grid>
                <Grid sx={showStoreFilt} item xs={12} md={2}>
                    <Autocomplete
                        // sx = {showStoreFilt}
                        disablePortal
                        onChange={(event, newValue) => {
                          console.log(newValue)
                            if(newValue == null){
                              setEngagementSubType('')
                            }else{
                              setEngagementSubType(newValue.label)
                            }
                          }}
                          options={optionsSubType}
                        // textFieldProps={{
                        //     onChange:(e)=>{onInputStoreChange(e)}
                        // }}
                        onOpen={(e)=>{onInputSubTypeOpen()}}
                        // sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} color="secondary" onChange={(e)=>{onInputSubTypeChange(e)}} label="Podtip" />}
                        />
                    
                </Grid>
                <Grid sx = {showRadnikFilt} item xs={12} md={2}>
                    <Autocomplete
                        disablePortal
                        onChange={(event, newValue) => {
                            if(newValue == null){
                              setJobFilt('')
                            }else{
                              setJobFilt(newValue.label)
                            }
                          }}
                        options={optionsJob}
                        onOpen={(e)=>{onInputJobOpen()}}
                        // sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} color="secondary" onChange={(e)=>{onInputJobChange(e)}} label="Radno mesto" />}
                        />
                    
                </Grid>
                {
                emptyGridColumns > 0 &&
                <Grid item xs={0} md={emptyGridColumns}></Grid>
                }
                {/* <Grid item xs={12} md={2} sx={{display:'flex',justifyContent:'right'}}><Button size="large" variant="outlined" color={'secondary'} onClick={() => { setOpenPopupVacation(true)}}>Odmor</Button></Grid> */}
                <Grid item xs={12} md={2} sx={{display:'flex',justifyContent:'right'}}><Button size="large" variant="outlined" onClick={() => { setOpenPopupCheckInOut(true)}}>Prijava/Odjava</Button></Grid>
                
            </Grid>
}
{screenSize <= 900 && 
 <Grid container spacing={2}  sx={{alignItems:'center'}}>
  {auth.role.toLowerCase() != "user" ? 
  <Grid item xs={5} md={2} ><IconButton color="secondary" onClick={() => { setFilterOpened(!filterOpened)}}>
        {filterOpened ? <AiOutlineFilter />: <AiFillFilter /> }
      </IconButton>
</Grid> 
  : <Grid item xs={5} md={2} ></Grid> 
} 
  <Grid item xs={7} md={2} sx={{display:'flex',justifyContent:'right'}}><Button size="large" variant="outlined" onClick={() => { setOpenPopupCheckInOut(true)}}>Prijava/Odjava</Button></Grid>
  <Grid sx={{...filtersx, ...showRadnikFilt}} item xs={12} md={2}>
                <Autocomplete
                    
                    disablePortal
                    onChange={(event, newValue) => {
                        if(newValue == null){
                          setEmployee('')
                        }else{
                          setEmployee(newValue.id)
                        }
                      }}
                    options={optionsEmployee}
                    defaultValue={{ label: employee, id: employee }}
                    onOpen={(e)=>{onInputEmployeeOpen()}}
                    // sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} color="secondary" onChange={(e)=>{onInputEmployeeChange(e)}} label="Radnik" />}
                    />
                
            </Grid>
            <Grid sx={{...filtersx, ...showStoreFilt}} item xs={12} md={2}>
                    <Autocomplete
                        disablePortal
                        onChange={(event, newValue) => {
                            if(newValue == null){
                              setStore(0)
                              setStoreName('')
                            }else{
                            setStore(newValue.id)
                            setStoreName(newValue.label)
                            }
                          }}
                        options={optionsStore}
                        defaultValue={{ label: storeName, id: store }}
                        // textFieldProps={{
                        //     onChange:(e)=>{onInputStoreChange(e)}
                        // }}
                        onOpen={(e)=>{onInputStoreOpen()}}
                        // sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} color="secondary" onChange={(e)=>{onInputStoreChange(e)}} label="Radnja" />}
                        />
                    
                </Grid>
                <Grid sx={{...filtersx, ...showStoreFilt}} item xs={12} md={2}>
                    <Autocomplete
                        // sx = {showStoreFilt}
                        disablePortal
                        onChange={(event, newValue) => {
                          console.log(newValue)
                            if(newValue == null){
                              setEngagementType('')
                            }else{
                              setEngagementType(newValue.label)
                            }
                          }}
                          options={optionsType}
                          
                        // textFieldProps={{
                        //     onChange:(e)=>{onInputStoreChange(e)}
                        // }}
                        onOpen={(e)=>{onInputTypeOpen()}}
                        // sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} color="secondary" onChange={(e)=>{onInputTypeChange(e)}} label="Tip" />}
                        />
                    
                </Grid>
                <Grid sx={{...filtersx, ...showStoreFilt}} item xs={12} md={2}>
                    <Autocomplete
                        // sx = {showStoreFilt}
                        disablePortal
                        onChange={(event, newValue) => {
                          console.log(newValue)
                            if(newValue == null){
                              setEngagementSubType('')
                            }else{
                              setEngagementSubType(newValue.label)
                            }
                          }}
                          options={optionsSubType}
                          onOpen={(e)=>{onInputSubTypeOpen()}}
                        // textFieldProps={{
                        //     onChange:(e)=>{onInputStoreChange(e)}
                        // }}
                        // onOpen={(e)=>{onInputStoreOpen()}}
                        // sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} color="secondary" onChange={(e)=>{onInputSubTypeChange(e)}} label="Podtip" />}
                        />
                    
                </Grid>
                <Grid sx={{...filtersx, ...showRadnikFilt}} item xs={12} md={2}>
                <Autocomplete
                        disablePortal
                        onChange={(event, newValue) => {
                            if(newValue == null){
                              setJobFilt('')
                            }else{
                              setJobFilt(newValue.label)
                            }
                          }}
                        options={optionsJob}
                        onOpen={(e)=>{onInputJobOpen()}}
                        // sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} color="secondary" onChange={(e)=>{onInputJobChange(e)}} label="Radno mesto" />}
                        />
                    
                </Grid>
 </Grid>
}
    </>
    
  )
}

export default CalendarFilters