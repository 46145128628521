
import React, {useState, useEffect} from 'react'
import useTable from './useTable'
import { Button, TextField, TableBody, TableRow, TableCell, Autocomplete, FormControlLabel, FormGroup, Checkbox, CircularProgress, Grid } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import locale from 'date-fns/locale/sr-Latn';
import { format, isBefore, isAfter } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import { getOptionsTrainings } from '../services/trainingService'

const ImportTable = ({recordsprop, setRecordprops}) => {
    const [records, setRecords] = useState(recordsprop)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [checkAll, setCheckAll] = useState(false)
    const [groupTraining, setGroupTraining] = useState(null)
    const [optionsTraining, setOptionsTraining] = useState([])
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    const headCells = [
        { id: 'acWorkerFullName', label: 'Zaposleni' },
        { id: 'acStore', label: 'Radnja' },
        { id: 'adDateStart', label: 'Početak' },
        { id: 'adDateEnd', label: 'Kraj'},
        { id: 'training', label: 'Trening', disableSorting: true },
        { id: 'selected', label: 'Obeleženi', disableSorting: true }
      ]

    useEffect(() => {
        getOptionsTrainings(setOptionsTraining,'')
    },[])
    useEffect(() => {
        if(checkAll != null){
            
            let filtered = filteredRecords()
            let filteredChecked = filtered.map(item => { return {...item, checked : checkAll}})
            
            
            let newRecords = records.map(item => { return item})
            filteredChecked.forEach(x => {
                let id = newRecords.findIndex(y => y.anId == x.anId)
                newRecords[id] = x
            })
            
            setRecords(newRecords)
        }
    }, [checkAll])

    useEffect(() => {
        
        // if(groupTraining != null){
            
            let newRecords = records.map(item => {
                if(item.checked){
                    return {...item, value : groupTraining}
                }else{
                    return item
                }
                
            })
            setRecords(newRecords)
        // }
    }, [groupTraining])

    const selectOptions = [
        {id:1, label:'Trening 1'},
        {id:2, label:'Trening 2'},
        {id:3, label:'Trening 3'}
      ]
      const {
          TblContainer,
          TblHead,
          TblPagination,
          recordsAfterPagingAndSorting,
          filteredRecords
      } = useTable(records, headCells, filterFn);
      
      useEffect(()=>{
        // if(startDate != null || endDate != null){
        
            setFilterFn({
                fn: items => {
                    if (startDate == null && endDate == null){
                        return items;
                    }
                    else if (startDate != null && endDate ==null){
                        return items.filter(x => isAfter(new Date(x.adDateStart), startDate))
                    }
                    else if (startDate == null && endDate !=null){
                        return items.filter(x => isBefore(new Date(x.adDateStart), endDate))
                    }
                    else if (startDate != null && endDate !=null){
                        return items.filter(x => {
                            return isBefore(new Date(x.adDateStart), endDate) && isAfter(new Date(x.adDateStart), startDate)
                        })
                    }
                }
            })
        // }
      },[startDate, endDate])

      const checkedItemChange = (item, val) => {
        let newItem = {...item, checked:val}
        let newRecords = records.map(item => {
            if(item.anId == newItem.anId){
                return newItem
            }else{
                return item
            }

        })
        setRecords(newRecords)
    }

    const valueItemChange = (item, val) => {
        
        let newItem = {...item, value:val}
        let newRecords = records.map(item => {
            if(item.anId == newItem.anId){
                return newItem
            }else{
                return item
            }

        })
        setRecords(newRecords)
    }

    useEffect(()=>{
        setRecordprops(records)
    },[records])

    const onInputTrainingChange = (e) => {
        getOptionsTrainings(setOptionsTraining,e.target.value)
      }
      const onInputTrainingOpen =  () => {
        getOptionsTrainings(setOptionsTraining,'')
      }
      const onKeyDown = (e) => {
        e.preventDefault();
      };


  return (
    <>
    <Grid container spacing={2}  sx={{alignItems:'center'}}>
                  <Grid item xs={12} md={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                    <DatePicker label="Početak" value={startDate} componentsProps={{
                            actionBar: {
                            actions: ['clear'],
                          },
                        }} onChange={(val)=>{setStartDate(val)}}
                        renderInput={(props) => <TextField color="secondary" onKeyDown={onKeyDown} {...props} />}
                    />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                    <DatePicker label="Kraj" value={endDate} componentsProps={{
                            actionBar: {
                            actions: ['clear'],
                          },
                        }} onChange={(val)=>{setEndDate(val)}} 
                        renderInput={(props) => <TextField color="secondary" onKeyDown={onKeyDown} {...props} />}
                    />
                    </LocalizationProvider>
                    
                </Grid>
                <Grid item xs={0} md={4}></Grid>
                <Grid item xs={12} md={2}>
                    <Autocomplete
                        disablePortal
                        onChange={(event, newValue) => {
                            setGroupTraining(newValue);
                          }}
                        options={optionsTraining}
                        textFieldProps={{
                            onChange:(e)=>{onInputTrainingChange(e)}
                        }}
                        onOpen={(e)=>{onInputTrainingOpen()}}
                        // sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} color="secondary" label="Grupni izbor treninga" />}
                        />
                    
                </Grid>
                <Grid item xs={12} md={2} style={{textAlign:'right'}}>
                    <FormGroup style={{display:"inline-flex", justifyContent:'flex-end'}}>
                        <FormControlLabel control={<Checkbox checked={checkAll}  onChange={(event) => {
                        
                            setCheckAll(event.target.checked);
                        }}/>} label="Obeleži sve" />
                    </FormGroup>
                </Grid>
            </Grid>
            <div className='mb-5'></div>

                
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map((item,id) =>{
                                
                                return (<TableRow key={item.anId}>
                                    <TableCell>{item.acWorkerFullName}</TableCell>
                                    <TableCell>{item.acStore}</TableCell>
                                    <TableCell>{format(new Date(item.adDateStart),'dd.MM.yyyy HH:mm')}</TableCell>
                                    <TableCell>{format(new Date(item.adDateEnd),'dd.MM.yyyy HH:mm')}</TableCell>
                                    <TableCell>
                                    <Autocomplete
                                        size="small"
                                        disablePortal
                                        value={item.value}
                                        options={optionsTraining}
                                        onChange={
                                            (e, newValue)=>
                                                {   
                                                    valueItemChange(item, newValue)
                                                 
                                                }
                                        }
                                        // textFieldProps={{
                                        //     onChange:(e)=>{onInputTrainingChange(e)}
                                        // }}
                                        // onOpen={(e)=>{onInputTrainingOpen()}}
                                        // sx={{ width: 300 }}
                                        renderInput={(params) => <TextField color="secondary" {...params} label="" />}
                                        />
                                    </TableCell>
                                    <TableCell  style={{textAlign:"right"}}><Checkbox checked={item.checked} onChange={
                                        (e)=>
                                            {
                                                let val = e.target.checked
                                                checkedItemChange(item, val)
                                                
                                            }
                                    }/></TableCell>
                                </TableRow>)
}
                            )
                        }
                    </TableBody>
                </TblContainer>
                <TblPagination />
    </>
  )
}

export default ImportTable