import { format } from 'date-fns';
import { url } from '../data/dummy';

export async function getAllCalendarEvents(info, setCalRecords, employee, store, engagementType, engagementSubType, jobFilt) {
    // console.log(info)
    let start = format(new Date(info.start), 'yyyy-MM-dd HH:mm')
    let end = format(new Date(info.end), 'yyyy-MM-dd HH:mm')
    
    fetch(url+'/calendar.php?type=events&start='+start+'&end='+end+'&employee='+employee+'&store='+store+'&engtype='+engagementType+'&engsubtype='+engagementSubType+'&job='+jobFilt, { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            
            let results = success.map(item => {
                // console.log(item)
                // if(employee != ""){
                    if(!item.isFullTime){
                        return {
                            id: item.anId,
                            title: item.acStore,
                            start: item.adDateTimeStart,
                            end: item.adDateTimeEnd,
                            extendedProps: item
                        }
                        
                    }else{
                        if(item.anTrainingID != null){
                            return {
                                id: item.anId,
                                title: item.acStore,
                                start: item.adDateTimeStart,
                                end: item.adDateTimeEnd,
                                extendedProps: item
                            }
                        }else{
                            return {
                                id: item.anId,
                                title: item.acEngagementType,
                                start: item.adDateTimeStart,
                                end: item.adDateTimeEnd,
                                textColor: 'black',
                                color:'transparent',
                                allDay: true,
                                extendedProps: item
                            }

                        }
                    }
                // }else if(store != ""){
                //     if(!item.isFullTime){
                //         return {
                //             id: item.anId,
                //             title: item.acWorkerFullName,
                //             start: item.adDateTimeStart,
                //             end: item.adDateTimeEnd,
                //             extendedProps: item
                //         }
                        
                //     }else {
                //         return {
                //             id: item.anId,
                //             title: item.acEngagementType +':'+item.acWorkerFullName,
                //             start: item.adDateTimeStart,
                //             // end: item.adDateTimeEnd,
                //             allDay: true,
                //             textColor: 'black',
                //             color:'transparent',
                //             extendedProps: item
                //         }
                //     }
                // }else{
                //     if(!item.isFullTime){
                //         return {
                //             id: item.anId,
                //             title: item.acStore+ '\n'+item.acWorkerFullName,
                //             start: item.adDateTimeStart,
                //             end: item.adDateTimeEnd,
                //             extendedProps: item
                //         }
                //     }else {
                //         return {
                //             id: item.anId,
                //             title: item.acEngagementType+'\n'+item.acWorkerFullName,
                //             start: item.adDateTimeStart,
                //             textColor: 'black',
                //             color:'transparent',
                //             allDay: true,
                //             extendedProps: item
                //         }
                //     }
                // }

            })
            setCalRecords(results)
            // setRecords(success)
        } // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}

export async function getOptionsEmployee(setRecords, value, storeName="", jobFilt="") {
    
    fetch(url+'/calendar.php?type=optionsemployee&value='+value+'&store='+storeName+'&job='+jobFilt, { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => setRecords(success) // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}


export async function getOptionsStoreId(setRecords, value) {
    
    fetch(url+'/calendar.php?type=optionsstoreid&value='+value, { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => setRecords(success) // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}

export async function getOptionsStore(setRecords, value) {
    
    fetch(url+'/calendar.php?type=optionsstore&value='+value, { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => setRecords(success) // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}

export async function getOptionsAttendType(setRecords, value) {
    
    fetch(url+'/checkin-checkout.php?type=optionsattendtype&value='+value, { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => setRecords(success) // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}






export async function saveCheckInOut(data, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading, setOpenPopupCheckInOut) {
    
    setSubmitLoading(true)
    fetch(url+'/checkin-checkout.php', { // Your POST endpoint
        method: 'POST',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            if(success[0].isOk == 0){
                setErrorMsg(success[0].acMessage)
                setError(true)
                
            }else{
                
                setSuccessMsg(success[0].acMessage)
                setSuccess(true)
                setTimeout(() => {setOpenPopupCheckInOut(false)},2000)
            }
            setSubmitLoading(false)
        } // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}




export async function insertUpdatePlan(data, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading, setOpenPopupPlan) {
    
    setSubmitLoading(true)
    fetch(url+'/calendar.php', { // Your POST endpoint
        method: 'POST',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            if(success[0].isOk == 0){
                setErrorMsg(success[0].acMessage)
                setError(true)
                
            }else{
                
                setSuccessMsg(success[0].acMessage)
                setSuccess(true)
                setTimeout(() => {setOpenPopupPlan(false)},2000)
            }
            setSubmitLoading(false)
        } // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}
