import { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from 'react-router-dom';

const Logout = () => {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    useEffect(()=>{
        setAuth({});
        localStorage.removeItem('user')
        navigate('/')
    },[])
    
    // const navigate = useNavigate();
    // const location = useLocation();
    // const from = "/";
    
    
    return (
        <></>
    )
    
}

export default Logout

