import { url } from '../data/dummy';
export function insertUpdateUser(data) {
    console.log('insert',data)
    fetch(url+'/employees.php', { // Your POST endpoint
        method: 'POST',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.text() // if the response is a JSON object
    ).then(
        success => console.log(success) // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}

export async function getAllEmployees(setRecords) {
    
    fetch(url+'/employees.php?type=list', { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => setRecords(success) // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}


export async function  uploadPlan (file, setRecords, setOtherErrors, setOtherErrorsMsg, setErrors, setTraining, setUploadLoading) {
    setUploadLoading(true)
    
    const formData = new FormData()
    
    formData.append('files[]', file)
    
    fetch(url+'/uploadplan.php', { // Your POST endpoint
      method: 'POST',
      // headers: {
      //   // Content-Type may need to be completely **omitted**
      //   // or you may need something
      //   // "Content-Type": "You will perhaps need to define a content-type here"
      //   "Content-Type": "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)
      // },
      body: formData // This is your file object
    }).then(
      response => response.json() // if the response is a JSON object
    ).then(
      success => {
         // Handle the success response object
        
            if(success.type=="date"){
                setOtherErrorsMsg('Pronađena je greška u datumskom polju, ispravite, pa ponovo uvezite plan!')
                setOtherErrors(true)
            }
            if(success.type=="error"){
                
                setRecords(success.result)
                setErrors(true)
            }
            if(success.type=="training"){
                
                setRecords(success.result)
                setTraining(true)
            }
            setUploadLoading(false)
        }
      
    ).catch(
      error => {
        console.log(error) // Handle the error response object
        setOtherErrorsMsg('Neuspešan upload excel-a!')
        setOtherErrors(true)
        setUploadLoading(false)
        }
    );
  }
