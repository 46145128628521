import React, { useState, useContext } from 'react'
import {FormContainer, TextFieldElement, PasswordElement, useForm} from 'react-hook-form-mui';
import { Button, Alert } from '@mui/material';
import useAuth from "../../hooks/useAuth";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { url } from '../../data/dummy';


const LoginForm = (props) => {
  
  const { auth, setAuth } = useAuth();
  
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  
  const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    
  const defaultValues =  {
    username: '',
    password: ''
  }

    const formContext = useForm({
      defaultValues: defaultValues
    })


  const {handleSubmit} = formContext

  
  
  const logIn = (data) => {
    setError(false)
    fetch(url+'/login.php', { // Your POST endpoint
        method: 'POST',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            if(success[0].isOk == 0){
                setErrorMsg(success[0].acMessage)
                setError(true)

            }else{
              let userData = { user:success[0].acWorkerID, store:success[0].acStore, storeId:success[0].anStoreID, role:success[0].acUserType }
              setAuth(userData);
              localStorage.setItem('user',JSON.stringify(userData))
              navigate(from, { replace: true });

            }
        } // Handle the success response object
    ).catch(
        error => {console.log(error)
          setErrorMsg('Neuspešna prijava, problem u komunikaciji sa serverom!')
          setError(true)
        } // Handle the error response object
    );
  }
  
  return (
    <>
    <div style={{display:'flex', alignItems:'center', justifyContent:'center', marginBottom:20}}>
    <img src="./logo_jasmin.png" title="Jasmin logo" alt="Jasmin logo" width="170" height="125"></img>
    </div>
    <FormContainer
    autoComplete="off"
      formContext={formContext}
      handleSubmit={handleSubmit(logIn)}
    >
      <TextFieldElement fullWidth margin="dense" name="username" label="Korisničko ime"
        type={'email'} required color="secondary" validation={{
          required: {
            value: true,
            message: ''
          }
        }}/>
        <div className='mb-5' />
        <PasswordElement
            color="secondary"
            fullWidth
            margin={'dense'}
            label={'Lozinka'}
            required
            name={'password'}
        />
        <div className='mb-5' />
      <Button type={'submit'} variant={'contained'} color={'primary'}>
        Prijavi se
      </Button>
      
    </FormContainer>
    {error && <Alert className="mt-5" severity="error">{errorMsg}</Alert>}
    </>
  )
}

export default LoginForm