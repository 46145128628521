import { url } from '../data/dummy';

export function insertUpdateUser(data, setRecords, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading, setRecordForEdit, setOpenPopupUser) {
    
    setSubmitLoading(true)
    fetch(url+'/users.php', { // Your POST endpoint
        method: 'POST',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success =>  {
            if(success[0].isOk == 0){
                setErrorMsg(success[0].acMessage)
                setError(true)
                
            }else{
                
                setSuccessMsg(success[0].acMessage)
                setSuccess(true)
                
                setRecords(prev => {
                    let newrec = JSON.parse(JSON.stringify(prev))
                    newrec.forEach(item => {
                        if(item.acWorkerID == data.workerId){
                            item.acUserLogIn = data.userId
                        }
                    })
                    return newrec
                })
                setTimeout(() => {
                    setRecordForEdit(null)
                    setOpenPopupUser(false)
                },2000)
            }
            setSubmitLoading(false)
        } // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}

export function insertUpdateEmployee(data, setRecords, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading, setRecordForEdit, setOpenPopupEmployee) {
    
    fetch(url+'/employees.php', { // Your POST endpoint
        method: 'POST',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            if(success[0].isOk == 0){
                setErrorMsg(success[0].acMessage)
                setError(true)
                
            }else{
                
                setSuccessMsg(success[0].acMessage)
                setSuccess(true)
                
                if(data.type.toLowerCase() == 'insert'){
                    setRecords(prev => {
                        return [...prev,{acWorkerID:data.id,
                            acName: data.name,
                            acSurname: data.lastName,
                            acWorkerFullName: data.name+' '+data.lastName ,
                            acPhone:data.phone,
                            acEmail:data.email,
                            acStore: data.store,
                            acStoreFullName: data.store,
                            acJob: data.job,
                            acUserLogIn: null,
                            acUserTYpe: null,
                            anERP_ID: -1}]
                    })
                }else{
                    setRecords(prev => {
                        let newrec = prev.map(item=>item)
                        newrec.forEach(item => {
                            if(item.acWorkerID == data.id){
                                item.acName= data.name
                                item.acSurname= data.lastName
                                item.acWorkerFullName = data.name+' '+data.lastName 
                                item.acPhone =data.phone
                                item.acEmail =data.email
                                item.acStore = data.store
                                item.acStoreFullName = data.store
                                item.acJob = data.job
                            }
                        })
                        return newrec
                    })
                }
                
                setTimeout(() => {
                    setRecordForEdit(null)
                    setOpenPopupEmployee(false)
                },2000)
            }
            setSubmitLoading(false)
        }  // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}

export function deleteEmployee(data, setRecords) {
    
    fetch(url+'/employees.php', { // Your POST endpoint
        method: 'DELETE',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            if(success[0].isOk == 0){
                alert(success[0].acMessage)
            }else{
                
                setRecords(prev => {
                    
                    return prev.filter(x => x.acWorkerID != data.acWorkerID)
                })
            }
        } // Handle the success response object // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}

export async function getAllEmployees(setRecords) {
    
    fetch(url+'/employees.php?type=list', { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => setRecords(success) // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}


export async function getOptionsJob(setRecords, value) {
    
    fetch(url+'/employees.php?type=optionsjob&value='+value, { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => setRecords(success) // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}

