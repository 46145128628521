import React, { useState, useEffect } from 'react';

import { TableBody, TableRow, TableCell, Button, Toolbar, InputAdornment, TextField, Grid, IconButton, Autocomplete } from '@mui/material';
import { makeStyles } from '@material-ui/styles'
import useTable from "../components/useTable";
import * as typeService from "../services/typeService";
import { Search } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import ResponsiveDialog from "../components/ResponsiveDialog";
import ResponsiveDialogConfirm from "../components/ResponsiveDialogConfirm";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import TypeForm from '../components/Forms/TypeForm'
import { useStateContext } from '../contexts/ContextProvider';
import { Header } from '../components';
import { AiFillFilter, AiOutlineFilter } from 'react-icons/ai'

const Types = () => {

  const { screenSize } = useStateContext();

  const headCells = screenSize <= 900 ?
[
  { id: 'acName', label: 'Naziv' },
  { id: 'acParentName', label: 'Nadređeni', disableSorting: true },
  { id: 'actions', label: 'Akcije', disableSorting: true }
]
:
[
  { id: 'acName', label: 'Naziv' },
  { id: 'acParentName', label: 'Nadređeni', disableSorting: true },
  { id: 'acdescription', label: 'Opis', disableSorting: true },
  { id: 'actions', label: 'Akcije', disableSorting: true }
]

  
useEffect(() => {
    typeService.getAllTypes(setRecords)
  }, []);

const [selectedType, setSelectedType] = useState('')
const [searchValue, setSearchValue] = useState('')
const [formTitle, setFormTitle] = useState('Novi trener')
const [recordForEdit, setRecordForEdit] = useState(null)
const [records, setRecords] = useState([])
const [filterFn, setFilterFn] = useState({ fn: items => {
                return items;
  } })
const [openPopup, setOpenPopup] = useState(false)
const [openPopupDel, setOpenPopupDel] = useState(false)

const [filterOpened,setFilterOpened] = useState(false)


const filtersx = filterOpened ? {}: {display:'none'} 

const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
} = useTable(records, headCells, filterFn);

const handleSearch = e => {
    let target = e.target;
    setSearchValue(target.value)
    // setFilterFn({
    //     fn: items => {
    //         if (target.value == "")
    //             return items;
    //         else
    //             return items.filter(x => x.acName.toLowerCase().includes(target.value))
    //     }
    // })
}

useEffect(()=>{
  setFilterFn({
        fn: items => {
          if (selectedType == "" && searchValue=="")
              return items;
          else if(selectedType != "" && searchValue=="")
              return items.filter(x => (selectedType == 'PODTIP' && x.acParentName != "") || (selectedType == 'TIP' && x.acParentName == ""))
          else if(selectedType == "" && searchValue!="")
              return items.filter(x => x.acName.toLowerCase().includes(searchValue))
          else if(selectedType != "" && searchValue!="")
            return items.filter(x => ((selectedType == 'PODTIP' && x.acParentName != "") || (selectedType == 'TIP' && x.acParentName == "")) && x.acName.toLowerCase().includes(searchValue))
          }
      })

},[searchValue, selectedType])

const addOrEdit = (supplier, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading) => {
  setSuccess(false)
  setError(false)
    typeService.insertUpdateType(supplier, setRecords, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading,setRecordForEdit, setOpenPopup) 
    // setRecordForEdit(null)
    // setOpenPopup(false)
  }
  
  const openInPopup = item => {
    setFormTitle('Izmena tipa/podtipa')
    setRecordForEdit(item)
    setOpenPopup(true)
  }
  
  const removeItem = (type) => {
   
    
    setRecordForEdit(type)
    setOpenPopupDel(true)
  }
  
  const deleteItem = () => {
    console.log(recordForEdit)
    typeService.deleteType(recordForEdit, setRecords) 
    setRecordForEdit(null)
    setOpenPopupDel(false)
  }
  
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Stranica" title="Tipovi i podtipovi" />
      {screenSize > 900 && <>
      <div className='mb-5'>
        <Button         
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => { setFormTitle('Novi tip/podtip'); setOpenPopup(true); setRecordForEdit(null); }}
        >Novi tip/podtip</Button>
      </div>
      <Grid container spacing={2}  sx={{alignItems:'center'}}>
                  <Grid item xs={12} md={2}>
                    <TextField
                      variant="outlined"
                      label="Naziv"
                      color="secondary"
                      fullWidth
                        InputProps={{
                            endAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                  />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Autocomplete
                        
                        disablePortal
                        onChange={(event, newValue) => {
                          console.log(newValue)
                            if(newValue == null){
                              setSelectedType('')
                            }else{
                              setSelectedType(newValue.label)
                            }
                          }}
                        options={[
                          {label:'TIP'},
                          {label:'PODTIP'}
                        ]}
                        // sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} color="secondary" label="Prikaz tip/podtip" />}
                        />
                  </Grid>
                  <Grid item xs={0} md={8}></Grid>
                </Grid></>}
                {screenSize <= 900 && <Grid container spacing={2}  sx={{alignItems:'center'}}>
   <Grid item xs={5} md={2} ><IconButton color="secondary" onClick={() => { setFilterOpened(!filterOpened)}}>
        {filterOpened ? <AiOutlineFilter />: <AiFillFilter /> }
      </IconButton>
</Grid> 

  <Grid item xs={7} md={2} sx={{display:'flex',justifyContent:'right'}}><Button         
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => { setFormTitle('Novi tip/podtip'); setOpenPopup(true); setRecordForEdit(null); }}
        >Novi tip/podtip</Button></Grid>
        <Grid item sx={filtersx} xs={12} md={2}>
        <TextField
                      variant="outlined"
                      label="Naziv"
                      color="secondary"
                      fullWidth
                        InputProps={{
                            endAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                  />
                  </Grid>
                  <Grid item sx={filtersx} xs={12} md={2}>
                    <Autocomplete
                        
                        disablePortal
                        onChange={(event, newValue) => {
                          console.log(newValue)
                            if(newValue == null){
                              setSelectedType('')
                            }else{
                              setSelectedType(newValue.label)
                            }
                          }}
                        options={[
                          {label:'TIP'},
                          {label:'PODTIP'}
                        ]}
                        // sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} color="secondary" label="Prikaz tip/podtip" />}
                        />
                  </Grid>
        </Grid>
  }
  
                <div className='mb-5'></div>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item.anAttendanceID}>
                                    <TableCell>{item.acName}</TableCell>
                                    <TableCell>{item.acParentName}</TableCell>
                                    {screenSize > 900 && <>
                                    <TableCell>{item.acdescription}</TableCell>
                                    
                                    </>
                                    }
                                    <TableCell style={{textAlign:'right', padding:0}}>
                                        <Button
                                            color="primary"
                                            onClick={() => { openInPopup(item) }}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </Button>
                                        <Button
                                            color="secondary"
                                            onClick={() => { removeItem(item) }}>
                                            <CloseIcon fontSize="small" />
                                        </Button>
                                    </TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                </TblContainer>
                <TblPagination />
                <ResponsiveDialog
                    title={formTitle}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >
                  <TypeForm 
                  recordForEdit={recordForEdit}
                  addOrEdit={addOrEdit} 
                  />
                    
                </ResponsiveDialog>
                <ResponsiveDialogConfirm
                  title={'Da li ste sigurni da želite da obrišete stavku?'}
                  open={openPopupDel}
                  setOpen={setOpenPopupDel}
                  handleConfirm={deleteItem}
                />

    </div>
  );
};
export default Types;

