import React, { useState, useEffect } from 'react';

import { TableBody, TableRow, TableCell, Button, Toolbar, InputAdornment, TextField, Grid, IconButton } from '@mui/material';
import { makeStyles } from '@material-ui/styles'
import useTable from "../components/useTable";
import * as coachService from "../services/coachService";
import { Search } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import ResponsiveDialog from "../components/ResponsiveDialog";
import ResponsiveDialogConfirm from "../components/ResponsiveDialogConfirm";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import CoachForm from '../components/Forms/CoachForm'
import { useStateContext } from '../contexts/ContextProvider';
import { Header } from '../components';
import { AiFillFilter, AiOutlineFilter } from 'react-icons/ai'

const Coaches = () => {
  const { screenSize } = useStateContext();

  const headCells = screenSize <= 900 ?
 [
  { id: 'acName', label: 'Ime i prezime' },
  { id: 'acNote', label: 'Opis', disableSorting: true },
  { id: 'actions', label: 'Akcije', disableSorting: true }
]:
[
  { id: 'acName', label: 'Ime i prezime' },
  { id: 'acEmail', label: 'Email' },
  { id: 'acPhone', label: 'Broj telefona' },
  { id: 'acNote', label: 'Opis', disableSorting: true },
  { id: 'actions', label: 'Akcije', disableSorting: true }
]
  
useEffect(() => {
  coachService.getAllCoaches(setRecords)
}, []);

const [formTitle, setFormTitle] = useState('Novi trener')
const [recordForEdit, setRecordForEdit] = useState(null)
const [records, setRecords] = useState([])
const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
const [openPopup, setOpenPopup] = useState(false)
const [openPopupDel, setOpenPopupDel] = useState(false)
const [filterOpened,setFilterOpened] = useState(false)


const filtersx = filterOpened ? {}: {display:'none'} 
const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
} = useTable(records, headCells, filterFn);

const handleSearch = e => {
    let target = e.target;
    setFilterFn({
        fn: items => {
            if (target.value == "")
                return items;
            else
                return items.filter(x => x.acName.toLowerCase().includes(target.value))
        }
    })
}

const addOrEdit = (coach, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading) => {
  setSuccess(false)
  setError(false)
  coachService.insertUpdateCoach(coach, setRecords, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading, setRecordForEdit, setOpenPopup) 
  // setRecordForEdit(null)
  // setOpenPopup(false)
}

const openInPopup = item => {
  setFormTitle('Izmena trenera')
  setRecordForEdit(item)
  setOpenPopup(true)
}

const removeItem = (coach) => {
  

  setRecordForEdit(coach)
  setOpenPopupDel(true)
}

const deleteItem = (coach) => {
  coachService.deleteCoach(recordForEdit, setRecords) 
  // checkInOutService.deleteCheckInOut(recordForEdit, setRecords) 
  setRecordForEdit(null)
  setOpenPopupDel(false)
}

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Stranica" title="Treneri" />
      {screenSize > 900 && <>
      <div className='mb-5'>
        <Button         
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => { setFormTitle('Novi trener'); setOpenPopup(true); setRecordForEdit(null); }}
        >Novi trener</Button>
      </div>
      <Grid container spacing={2}  sx={{alignItems:'center'}}>
                  <Grid item xs={12} md={2}>
                    <TextField
                      variant="outlined"
                      label="Ime i prezime"
                      color="secondary"
                      fullWidth
                        InputProps={{
                            endAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                  />
                  </Grid>
                  <Grid item xs={0} md={10}></Grid>
                </Grid>
                </>}
                {screenSize <= 900 && <Grid container spacing={2}  sx={{alignItems:'center'}}>
   <Grid item xs={5} md={2} ><IconButton color="secondary" onClick={() => { setFilterOpened(!filterOpened)}}>
        {filterOpened ? <AiOutlineFilter />: <AiFillFilter /> }
      </IconButton>
</Grid> 

  <Grid item xs={7} md={2} sx={{display:'flex',justifyContent:'right'}}><Button         
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => { setFormTitle('Novi trener'); setOpenPopup(true); setRecordForEdit(null); }}
        >Novi trener</Button></Grid>
        <Grid item sx={filtersx} xs={12} md={2}>
                <TextField
                      variant="outlined"
                      label="Ime i prezime"
                      color="secondary"
                      fullWidth
                        InputProps={{
                            endAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                  />
                  </Grid>
        </Grid>
  }
                <div className='mb-5'></div>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item.anTrainerID}>
                                    <TableCell>{item.acName}</TableCell>
                                    {screenSize > 900 && <><TableCell>{item.acEmail}</TableCell>
                                    <TableCell>{item.acPhone}</TableCell>
                                    </>}
                                    <TableCell>{item.acNote}</TableCell>
                                    <TableCell style={{textAlign:'right', padding:0}}>
                                        <Button
                                            color="primary"
                                            onClick={() => { openInPopup(item) }}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </Button>
                                        <Button
                                            color="secondary"
                                            onClick={() => { removeItem(item) }}>
                                            <CloseIcon fontSize="small" />
                                        </Button>
                                    </TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                </TblContainer>
                <TblPagination />
                <ResponsiveDialog
                    title={formTitle}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                >
                  <CoachForm 
                  recordForEdit={recordForEdit}
                  addOrEdit={addOrEdit} 
                  />
                    
                </ResponsiveDialog>
                <ResponsiveDialogConfirm
                  title={'Da li ste sigurni da želite da obrišete stavku?'}
                  open={openPopupDel}
                  setOpen={setOpenPopupDel}
                  handleConfirm={deleteItem}
                />

    </div>
  );
};
export default Coaches;

