import React, { useState, useEffect } from 'react';

import { TableBody, TableRow, TableCell, Button, Toolbar, InputAdornment, TextField, Grid, IconButton } from '@mui/material';
import { makeStyles } from '@material-ui/styles'
import useTable from "../components/useTable";
import * as employeeService from "../services/employeeService";
import { Search } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import ResponsiveDialog from "../components/ResponsiveDialog";
import ResponsiveDialogConfirm from "../components/ResponsiveDialogConfirm";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import KeyIcon from '@mui/icons-material/Key';
import EmployeeForm from '../components/Forms/EmployeeForm'
import UserForm from '../components/Forms/UserForm'
import { useStateContext } from '../contexts/ContextProvider';
import { AiFillFilter, AiOutlineFilter } from 'react-icons/ai'

import { Header } from '../components';

const Employees = () => {
  const { screenSize } = useStateContext();

  const headCells = screenSize <= 900 ?[
      { id: 'acWorkerFullName', label: 'Ime i prezime' },
      { id: 'acJob', label: 'Radno mesto' },
      { id: 'actions', label: 'Akcije', disableSorting: true }
    ]
  :
    [
      { id: 'acWorkerFullName', label: 'Ime i prezime' },
      { id: 'acStoreFullName', label: 'Radnja' },
      { id: 'acJob', label: 'Radno mesto' },
      { id: 'actions', label: 'Akcije', disableSorting: true }
    ]
  




  
useEffect(() => {
    employeeService.getAllEmployees(setRecords)
  }, []);
  
const [formEmployeeTitle, setFormEmployeeTitle] = useState('Novi zaposleni')
const [formUserTitle, setFormUserTitle] = useState('Novi nalog')
const [userFormType, setUserFormType] = useState('INSERT')
const [recordForEdit, setRecordForEdit] = useState(null)
const [records, setRecords] = useState([])
const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
const [openPopupEmployee, setOpenPopupEmployee] = useState(false)
const [openPopupUser, setOpenPopupUser] = useState(false)
const [openPopupDel, setOpenPopupDel] = useState(false)
const [filterOpened,setFilterOpened] = useState(false)

const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
} = useTable(records, headCells, filterFn);

const handleSearch = e => {
    let target = e.target;
    setFilterFn({
        fn: items => {
            if (target.value == "")
                return items;
            else
                return items.filter(x => x.acWorkerFullName.toLowerCase().includes(target.value.toLowerCase()))
        }
    })
}
const addOrEdit = (employee, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading) => {
  
  employeeService.insertUpdateEmployee(employee, setRecords, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading, setRecordForEdit, setOpenPopupEmployee)
  // setRecordForEdit(null)
  // setOpenPopupEmployee(false)
}

const addOrEditUser = (user,setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading) => {
  
  setSuccess(false)
  setError(false)
  employeeService.insertUpdateUser(user, setRecords, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading, setRecordForEdit, setOpenPopupUser)
  // setRecordForEdit(null)
  // setOpenPopupUser(false)
}
const filtersx = filterOpened ? {}: {display:'none'} 
const openInPopupEmployee = item => {
  setFormEmployeeTitle('Izmena zaposlenog')
  setRecordForEdit(item)
  setOpenPopupEmployee(true)
}
const openInPopupUser = (item, type) => {
  setUserFormType(type)
  setFormUserTitle('Izmena naloga')
  setRecordForEdit(item)
  setOpenPopupUser(true)
}
const openInPopupDel = item => {
    
  setRecordForEdit(item)
  setOpenPopupDel(true)
}

const deleteItem = () => {
  
  employeeService.deleteEmployee(recordForEdit, setRecords) 
  setRecordForEdit(null)
  setOpenPopupDel(false)
}


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Stranica" title="Zaposleni" />
      {screenSize > 900 && <><div className='mb-5'>
        <Button         
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => { setFormEmployeeTitle('Novi zaposleni'); setOpenPopupEmployee(true); setRecordForEdit(null); }}
        >Novi zaposleni</Button>
      </div>
      <Grid container spacing={2}  sx={{alignItems:'center'}}>
      <Grid item xs={12} md={2}>
                    <TextField
                      variant="outlined"
                      label="Ime i prezime"
                      color="secondary"
                      fullWidth
                        InputProps={{
                            endAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                  />
                  </Grid>
                  <Grid item xs={0} md={10}></Grid>
                </Grid>
                <div className='mb-5'></div>
                </>}
                {screenSize <= 900 && <Grid container spacing={2}  sx={{alignItems:'center'}}>
   <Grid item xs={5} md={2} ><IconButton color="secondary" onClick={() => { setFilterOpened(!filterOpened)}}>
        {filterOpened ? <AiOutlineFilter />: <AiFillFilter /> }
      </IconButton>
</Grid> 

  <Grid item xs={7} md={2} sx={{display:'flex',justifyContent:'right'}}><Button         
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => { setFormEmployeeTitle('Novi zaposleni'); setOpenPopupEmployee(true); setRecordForEdit(null); }}
        >Novi zaposleni</Button></Grid>
        <Grid item sx={filtersx} xs={12} md={2}>
                    <TextField
                      variant="outlined"
                      label="Ime i prezime"
                      color="secondary"
                      fullWidth
                        InputProps={{
                            endAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                  />
                  </Grid>
        </Grid>
  }
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item.acWorkerID}>
                                    <TableCell>{item.acWorkerFullName}</TableCell>
                                    {screenSize > 900 && <TableCell>{item.acStoreFullName}</TableCell>}
                                    <TableCell>{item.acJob}</TableCell>
                                    <TableCell style={{textAlign:'right', padding:0}}>
                                        {item.anERP_ID == -1 && 
                                        <>
                                        <Button
                                            color="secondary"
                                            onClick={() => {openInPopupDel(item) }}>
                                            <CloseIcon fontSize="small" />
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={() => { openInPopupEmployee(item) }}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </Button>
                                        </>
                                        }
                                        <Button
                                            color="primary"
                                            onClick={() => { openInPopupUser(item,(item.acUserLogIn == null ? 'INSERT' : 'UPDATE')) }}>
                                            {item.acUserLogIn == null ?<AddIcon fontSize="small" />:<KeyIcon fontSize="small" />}
                                        </Button>
                                    </TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                </TblContainer>
                <TblPagination />
                <ResponsiveDialog
                    title={formEmployeeTitle}
                    openPopup={openPopupEmployee}
                    setOpenPopup={setOpenPopupEmployee}
                >
                  {/* <EmployeeForm /> */}
                    <EmployeeForm
                        recordForEdit={recordForEdit}
                        addOrEdit={addOrEdit} />
                </ResponsiveDialog>
                <ResponsiveDialog
                    title={formUserTitle}
                    openPopup={openPopupUser}
                    setOpenPopup={setOpenPopupUser}
                >
                  {/* <EmployeeForm /> */}
                    <UserForm
                        recordForEdit={recordForEdit}
                        addOrEdit={addOrEditUser} 
                        type={userFormType}
                        />
                </ResponsiveDialog>
                <ResponsiveDialogConfirm
                  title={'Da li ste sigurni da želite da obrišete stavku?'}
                  open={openPopupDel}
                  setOpen={setOpenPopupDel}
                  handleConfirm={deleteItem}
                />

    </div>
  );
};
export default Employees;

