import React, { useState } from 'react'
import {FormContainer, TextFieldElement, TextareaAutosizeElement, useForm} from 'react-hook-form-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';


const CoachForm = (props) => {
  const { addOrEdit, recordForEdit } = props
  const [success, setSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const defaultValues = (recordForEdit != null ? {
    id: recordForEdit.anTrainerID,
    name: recordForEdit.acName,
    address: recordForEdit.acAddress,
    phone: recordForEdit.acPhone,
    email: recordForEdit.acEmail,
    note: recordForEdit.acNote
  } : {
    id:0,
    name: '',
    address: '',
    phone: '',
    email: '',
    note: ''
  })

  const formContext = useForm({
    defaultValues: defaultValues
  })


  const {handleSubmit} = formContext

  const saveCoach = (data) => {
    addOrEdit(data, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading)
  }
  
  return (
    <FormContainer
      formContext={formContext}
      handleSubmit={handleSubmit(saveCoach)}
    >
      <TextFieldElement color="secondary" fullWidth margin="dense" sx={{display:'none'}} type="number" name="id"/>
      <TextFieldElement color="secondary" fullWidth margin="dense" name="name" label="Ime i prezime" required validation={{
              required: {
                value: true,
                message: ''
              }
            }}/>
      <TextFieldElement color="secondary" fullWidth margin="dense" name="address" label="Adresa" required validation={{
              required: {
                value: true,
                message: ''
              }
            }}/>
      <TextFieldElement color="secondary" fullWidth margin="dense" name="phone" label="Telefon" required validation={{
          required: {
            value: true,
            message: ''
          }
        }}/>
      <TextFieldElement color="secondary" fullWidth margin="dense" name="email" label="Email"
        type={'email'} required validation={{
          required: {
            value: true,
            message: ''
          }
        }}/>
      <TextareaAutosizeElement color="secondary" fullWidth margin="dense" name="note" label="Napomena" resizeStyle="none" rows="4"/>
      <br />
      <LoadingButton loading={submitLoading} type={'submit'} color={'primary'}>
          Potvrdi
      </LoadingButton>
      <div className="mb-5"></div>
      {success && <div className="mb-5"><Alert severity="success">{successMsg}</Alert></div>}
      {error && <div className="mb-5"><Alert severity="error">{errorMsg}</Alert></div>}
    </FormContainer>
  )
}

export default CoachForm