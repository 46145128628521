import LoginForm from './Forms/LoginForm'
import Paper from '@mui/material/Paper';
import useAuth from "../hooks/useAuth";
import { useNavigate} from 'react-router-dom';
import { useEffect, useState } from 'react';



const Login = () => {

  const { setAuth } = useAuth();
  const [render, setRender] = useState(null)

  const navigate = useNavigate();
    useEffect(()=>{
      let localUser = localStorage.getItem('user')
      if(localUser != null){
          setAuth(JSON.parse(localUser))
          navigate('/')
      }else{
          setRender('')
      }
        
    },[])

  if(render == null) {
    return null
  }
  
  return (
    <div style={{display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'rgb(250 251 251)'}} className={
        `dark:bg-main-bg bg-main-bg min-h-screen w-full flex-2'`
    }>
        <Paper elevation={0} style={{background:'white', width:500,  borderRadius:'15px', padding: 25}}>
            <LoginForm />
        </Paper>
    </div>
  )
}

export default Login

