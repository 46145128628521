import { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    // useEffect(()=>{
    //     let localUser = localStorage.getItem('user')
    //     if(localUser != null){
    //         setAuth(JSON.parse(localUser))
    //     }
    // },[])
    

    // return (
    //     auth?.roles?.find(role => allowedRoles?.includes(role))
    //         ? <Outlet />
    //         : auth?.user
    //             ? <Navigate to="/unauthorized" state={{ from: location }} replace />
    //             : <Navigate to="/login" state={{ from: location }} replace />
    // );
    return (
        auth?.user
            ? <Outlet />
            :  <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;