import React, { useState, useEffect } from 'react';
import { url } from '../data/dummy';
import { TableBody, TableRow, TableCell, Button, Toolbar, InputAdornment, TextField, Grid, IconButton, Autocomplete } from '@mui/material';
import { Header } from '../components';

import {FaRegTrashAlt} from 'react-icons/fa'

import * as calendarService from "../services/calendarService";


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import locale from 'date-fns/locale/sr-Latn';
import { format, isBefore, isAfter, isEqual } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';

import LoadingButton from '@mui/lab/LoadingButton';

const RemovePlan = () => {
  
  
  const [employee, setEmployee] = useState('')
  const [storeName, setStoreName] = useState('')
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const [optionsEmployee, setOptionsEmployee] = useState([])
  const [optionsStore, setOptionsStore] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)

  const onInputEmployeeChange = (e) => {
    calendarService.getOptionsEmployee(setOptionsEmployee, e.target.value, storeName)
  }
  const onInputEmployeeOpen = (e) => {
    calendarService.getOptionsEmployee(setOptionsEmployee, '', storeName)
  }
  const onInputStoreChange = (e) => {
    calendarService.getOptionsStoreId(setOptionsStore, e.target.value)
  }
  const onInputStoreOpen = (e) => {
    calendarService.getOptionsStoreId(setOptionsStore, '')
  }

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const removePlan = () => {
    if(dateFrom != null && dateTo!=null){
      setSubmitLoading(true)
      console.log('brisanje')
      console.log({dateFrom:format(dateFrom,'yyyy-MM-dd'),dateTo:format(dateTo,'yyyy-MM-dd'), employee: employee, store:storeName})
      const data = {dateFrom:format(dateFrom,'yyyy-MM-dd'),dateTo:format(dateTo,'yyyy-MM-dd'), employee: employee, store:storeName}
      
      deleteType(data)
    }else{
      alert('Potrebno je odabrati period za brisanje plana!')
    }
    
  }

  function deleteType(data) {
    
    fetch(url+'/removeplan.php', { // Your POST endpoint
        method: 'DELETE',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
              alert(success[0].acMessage)
              setSubmitLoading(false)
        } // Handle the success response object // Handle the success response object
    ).catch(
        error => {console.log(error) // Handle the error response object
          setSubmitLoading(false)
        }
    );
}
  
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Stranica" title="Brisanje plana" />
      
      <div className='mb-5'>
      <LoadingButton loading={submitLoading} type={'submit'} variant="outlined"
            startIcon={<FaRegTrashAlt />}
            onClick={() => { removePlan() }} color={'primary'}>
          Obriši plan
        </LoadingButton>
        {/* <Button         
            variant="outlined"
            startIcon={<FaRegTrashAlt />}
            onClick={() => { removePlan() }}
        >Obriši plan</Button> */}
      </div>
      <Grid container spacing={2}  sx={{alignItems:'center'}}>
        <Grid item xs={12} md={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <DatePicker label="Datum od" maxDate={dateTo} value={dateFrom} componentsProps={{
                    actionBar: {
                    actions: ['clear'],
                  },
                }} onChange={(val)=>{setDateFrom(val)}}
                renderInput={(props) => <TextField fullWidth color="secondary" onKeyDown={onKeyDown} {...props} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <DatePicker label="Datum do" minDate={dateFrom} value={dateTo} componentsProps={{
                    actionBar: {
                    actions: ['clear'],
                  },
                }} onChange={(val)=>{setDateTo(val)}}
                renderInput={(props) => <TextField fullWidth color="secondary" onKeyDown={onKeyDown} {...props} />}
            />
            </LocalizationProvider>
        </Grid>
            <Grid item xs={12} md={2}>
                <Autocomplete
                    
                    disablePortal
                    onChange={(event, newValue) => {
                        if(newValue == null){
                          setEmployee('')
                        }else{
                          setEmployee(newValue.id)
                        }
                      }}
                    options={optionsEmployee}
                    defaultValue={{ label: employee, id: employee }}
                    onOpen={(e)=>{onInputEmployeeOpen()}}
                    // sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} color="secondary" onChange={(e)=>{onInputEmployeeChange(e)}} label="Radnik" />}
                    />
                
            </Grid>
            <Grid item xs={12} md={2}>
                    <Autocomplete
                        
                        disablePortal
                        onChange={(event, newValue) => {
                            if(newValue == null){
                              setStoreName('')
                            }else{
                            setStoreName(newValue.label)
                            }
                          }}
                        options={optionsStore}
                        defaultValue={{ label: storeName }}
                        // textFieldProps={{
                        //     onChange:(e)=>{onInputStoreChange(e)}
                        // }}
                        onOpen={(e)=>{onInputStoreOpen()}}
                        // sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} color="secondary" onChange={(e)=>{onInputStoreChange(e)}} label="Radnja" />}
                        />
                    
                </Grid>
                
                
            </Grid>

    </div>
  );
};
export default RemovePlan;

