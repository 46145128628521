import React, {useState} from 'react'
import { AutocompleteElement, FormContainer, TextareaAutosizeElement, TextFieldElement, useForm,RadioButtonGroup } from 'react-hook-form-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import * as typeService from '../../services/typeService'

const TypeForm = (props) => {
  const { addOrEdit, recordForEdit } = props
  const [success, setSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [optionsType, setOptionsType] = useState([])
  const [showFull, setShowFull] = useState(true) 
  
  const onInputTypeChange = (e) => {
    console.log('aga')
    typeService.getOptionsType(setOptionsType, e.target.value)
  }
  const onInputTypeOpen = (e) => {
    console.log('open')
    typeService.getOptionsType(setOptionsType, '')
  }

  const defaultValues = (recordForEdit != null ? {
    id: recordForEdit.anAttendanceID,
    name: recordForEdit.acName,
    note: recordForEdit.acdescription,
    parent: recordForEdit.acParentName,
    fullTime: recordForEdit.isFullTime.toString()
  } : {
    id:0,
    name: '',
    note: '',
    parent: '',
    fullTime:'1'
  })
    const formContext = useForm({
      defaultValues: defaultValues
    })

    
  
    const {handleSubmit, watch} = formContext

    const watchShowAge = watch('parent')

    React.useEffect(() => {
      console.log('watchShowAge',watchShowAge)
      if(watchShowAge == null || watchShowAge == ''){
        console.log('prazan')
        setShowFull(true)
      }else{
        console.log('popunjen')
        setShowFull(false)
      }

    }, [watchShowAge])
  
    const saveType = (data) => {
      console.log(data)
      let submitdata = {...data }
      submitdata.parent = submitdata.parent == null || submitdata.parent == '' ? '' :(typeof submitdata.parent== 'object' ? submitdata.parent.label : submitdata.parent)
      console.log(submitdata)
      addOrEdit(submitdata, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading)
    }
    
    return (
      <FormContainer
        formContext={formContext}
        handleSubmit={handleSubmit(saveType, saveType)}
      >
        <TextFieldElement color="secondary" fullWidth margin="dense" sx={{display:'none'}} type="number" name="id"/>
        <TextFieldElement color="secondary" fullWidth margin="dense" name="name" label="Naziv" required validation={{
                required: {
                  value: true,
                  message: ''
                }
              }}/>
        
        <TextareaAutosizeElement color="secondary" fullWidth margin="dense" name="note" label="Napomena" resizeStyle="none" rows="4"/>
        <AutocompleteElement
            name="parent"
            label="Nadređeni tip"
            
            color="secondary"
            textFieldProps={{
                color:"secondary",
                fullWidth: true,
                margin:"dense",
                onChange:(e)=>{onInputTypeChange(e)}
                
                
            }}
            autocompleteProps={{
                onOpen:(e)=>{onInputTypeOpen(e)}
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={optionsType}
            
          />
          { showFull &&
            <RadioButtonGroup
              // label="Basic"
              
              name="fullTime"
              options={[
                {
                  id: '1',
                  label: 'Ceo dan'
                },
                {
                  id: '0',
                  label: 'Vreme'
                }
              ]}
            />
          }
          
          
        <br />
        <LoadingButton loading={submitLoading} type={'submit'} color={'primary'}>
          Potvrdi
        </LoadingButton>
        <div className="mb-5"></div>
        {success && <div className="mb-5"><Alert severity="success">{successMsg}</Alert></div>}
        {error && <div className="mb-5"><Alert severity="error">{errorMsg}</Alert></div>}
      </FormContainer>
    )
  }

export default TypeForm