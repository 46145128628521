import { url } from '../data/dummy';

export function deleteTraining(data, setRecords) {
    
    fetch(url+'/trainings.php', { // Your POST endpoint
        method: 'DELETE',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            if(success[0].isOk == 0){
                alert(success[0].acMessage)
            }else{
                
                setRecords(prev => {
                    
                    return prev.filter(x => x.anTrainingID != data.anTrainingID)
                })
            }
        } // Handle the success response object // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}

export function insertUpdateTraining(data, dataFull, setRecords, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading, setRecordForEdit, setOpenPopup) {
    
    setSubmitLoading(true)
    fetch(url+'/trainings.php', { // Your POST endpoint
        method: 'POST',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            if(success[0].isOk == 0){
                setErrorMsg(success[0].acMessage)
                setError(true)
                
            }else{
                
                setSuccessMsg(success[0].acMessage)
                setSuccess(true)
                
                
                if(data.id == 0){
                    setRecords(prev => {
                        return [...prev,{
                            anTrainingID: success[0].anId,
                            acName: data.name,
                            acDescription: data.note,
                            acTrainingType: data.type,
                            adDateStart: dataFull.start,
                            adDateEnd: dataFull.end,
                            anSupplierID: dataFull.supplier.id,
                            acSupplierName: dataFull.supplier.label,
                            anTrainerID: dataFull.coach.id,
                            acTrainerName: dataFull.coach.label,
                            adTimeChg: null,
                            }]
                    })
                }else{
                    setRecords(prev => {
                    let newrec = prev.map(item=>item)
                        newrec.forEach(item => {
                            if(item.anTrainingID == data.id){
                                item.acName= data.name
                                item.acDescription = data.note
                                item.acTrainingType = data.type
                                item.adDateStart = dataFull.start
                                item.adDateEnd = dataFull.end
                                item.anSupplierID = dataFull.supplier.id
                                item.acSupplierName = dataFull.supplier.label
                                item.anTrainerID = dataFull.coach.id
                                item.acTrainerName = dataFull.coach.label
                            }
                        })
                        return newrec
                    })

                }

                setTimeout(() => {
                    setRecordForEdit(null)
                    setOpenPopup(false)
                },2000)
            }
            setSubmitLoading(false)
        } // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}

export async function getAllTrainings(setRecords) {
    
    fetch(url+'/trainings.php?type=list', { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            let results = success.map(item => {
                item.adDateStart = new Date(item.adDateStart)
                item.adDateEnd = new Date(item.adDateEnd)
                return item
            })
            setRecords(results)
        } // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}

export async function getOptionsTrainings(setRecords, value, date) {
    
    fetch(url+'/trainings.php?type=options&value='+value+'&date='+date, { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => setRecords(success) // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}
