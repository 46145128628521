import React, { useState, useEffect } from 'react';
import { Header } from '../components'
import { Button, TextField, TableBody, TableRow, TableCell, Grid, InputAdornment, IconButton } from '@mui/material';
import { BiUpload, BiCheck } from 'react-icons/bi';
import useTable from "../components/useTable";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import locale from 'date-fns/locale/sr-Latn';
import { format, isBefore, isAfter, isEqual } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import { Search } from "@material-ui/icons";
import AddIcon from '@material-ui/icons/Add';
import ResponsiveDialog from "../components/ResponsiveDialog";
import ResponsiveDialogConfirm from "../components/ResponsiveDialogConfirm";

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';
import * as trainingService from "../services/trainingService";
import TrainingForm from '../components/Forms/TrainingForm'
import { useStateContext } from '../contexts/ContextProvider';
import { AiFillFilter, AiOutlineFilter } from 'react-icons/ai'
import useAuth from '../hooks/useAuth';

const Trainings = () => {
  const { screenSize } = useStateContext();
  const { auth } = useAuth();
  const headCells = screenSize <= 900 ?
    [
      // { id: 'anTrainingID', label: 'Šifra' },
      { id: 'adDate', label: 'Datum' },
      { id: 'acName', label: 'Naziv' },
      { id: 'acDescription', label: 'Opis', disableSorting: true },
      ...auth.role.toLowerCase() == "manager" ? [{ id: 'actions', label: 'Akcije', disableSorting: true }] : []

    ]
    :
    [
      { id: 'anTrainingID', label: 'Šifra' },
      { id: 'acName', label: 'Naziv' },
      { id: 'acSupplierName', label: 'Dobavljač' },
      { id: 'acTrainerName', label: 'Trener' },
      { id: 'adDate', label: 'Datum' },
      { id: 'acDescription', label: 'Opis', disableSorting: true },
      ...auth.role.toLowerCase() == "manager" ? [{ id: 'actions', label: 'Akcije', disableSorting: true }] : []
    ]


  const [records, setRecords] = useState([])
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const [startDate, setStartDate] = useState(null)
  const [openPopup, setOpenPopup] = useState(false)
  const [recordForEdit, setRecordForEdit] = useState(null)
  const [formTitle, setFormTitle] = useState('Novi trening')
  const [searchFilter, setSearchFilter] = useState('')
  const [openPopupDel, setOpenPopupDel] = useState(false)
  const [filterOpened, setFilterOpened] = useState(false)


  const filtersx = filterOpened ? {} : { display: 'none' }

  useEffect(() => {
    trainingService.getAllTrainings(setRecords)
  }, []);

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    filteredRecords
  } = useTable(records, headCells, filterFn);

  const handleSearch = e => {
    let target = e.target;
    setSearchFilter(e.target.value)
  }

  useEffect(() => {
    setFilterFn({
      fn: items => {
        if (startDate == null && searchFilter == "")
          return items;
        else if (startDate != null && searchFilter == "")
          return items.filter(x => format(x.adDateStart, 'yyyy-MM-dd') == format(startDate, 'yyyy-MM-dd'))
        else if (startDate == null && searchFilter != "")
          return items.filter(x => x.acName.toLowerCase().includes(searchFilter))
        else if (startDate != null && searchFilter != "")
          return items.filter(x => x.acName.toLowerCase().includes(searchFilter) && format(x.adDateStart, 'yyyy-MM-dd') == format(startDate, 'yyyy-MM-dd'))
      }
    })
  }, [startDate, searchFilter])

  const openInPopup = item => {
    setFormTitle('Izmena treninga')
    setRecordForEdit(item)
    setOpenPopup(true)
  }


  const addOrEdit = (training, trainingFull, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading) => {
    setSuccess(false)
    setError(false)
    trainingService.insertUpdateTraining(training, trainingFull, setRecords, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading, setRecordForEdit, setOpenPopup)
    // setRecordForEdit(null)
    // setOpenPopup(false)
  }

  const removeItem = (training) => {

    setRecordForEdit(training)
    setOpenPopupDel(true)
  }

  const deleteItem = () => {

    trainingService.deleteTraining(recordForEdit, setRecords)
    setRecordForEdit(null)
    setOpenPopupDel(false)
  }

  const onKeyDown = (e) => {
    e.preventDefault();
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header className="mb-10" category="Stranica" title="Treninzi" />
      {screenSize > 900 && <>
        {auth.role.toLowerCase() == "manager" &&
          <div className="mb-5">
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
            >Novi trening</Button>
          </div>

        }
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
          <Grid item xs={12} md={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
              <DatePicker componentsProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }} label="Datum" value={startDate} onChange={(val) => { setStartDate(val) }}
                renderInput={(props) => <TextField fullWidth color="secondary" onKeyDown={onKeyDown} {...props} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              variant="outlined"
              label="Naziv treninga"
              color="secondary"
              fullWidth
              InputProps={{
                endAdornment: (<InputAdornment position="start">
                  <Search />
                </InputAdornment>)
              }}
              onChange={handleSearch}
            />
          </Grid>
          <Grid item xs={0} md={8}></Grid>
        </Grid>
      </>}
      {screenSize <= 900 && <Grid container spacing={2} sx={{ alignItems: 'center' }}>
        <Grid item xs={5} md={2} ><IconButton color="secondary" onClick={() => { setFilterOpened(!filterOpened) }}>
          {filterOpened ? <AiOutlineFilter /> : <AiFillFilter />}
        </IconButton>
        </Grid>
        {auth.role.toLowerCase() == "manager" &&
          <Grid item xs={7} md={2} sx={{ display: 'flex', justifyContent: 'right' }}><Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
          >Novi trening</Button></Grid>

        }
        <Grid sx={filtersx} item xs={12} md={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <DatePicker componentsProps={{
              actionBar: {
                actions: ['clear'],
              },
            }} label="Datum" value={startDate} onChange={(val) => { setStartDate(val) }}
              renderInput={(props) => <TextField fullWidth color="secondary" onKeyDown={onKeyDown} {...props} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid sx={filtersx} item xs={12} md={2}>
          <TextField
            variant="outlined"
            label="Naziv treninga"
            color="secondary"
            fullWidth
            InputProps={{
              endAdornment: (<InputAdornment position="start">
                <Search />
              </InputAdornment>)
            }}
            onChange={handleSearch}
          />
        </Grid>
      </Grid>
      }
      <div className='mb-5'></div>
      <TblContainer>
        <TblHead />
        <TableBody>
          {
            recordsAfterPagingAndSorting().map(item =>
            (<TableRow key={item.anTrainingID}>
              {screenSize <= 900 && <>
                <TableCell>{format(item.adDateStart, 'dd.MM.yyyy')} / {format(item.adDateEnd, 'dd.MM.yyyy')} {format(item.adDateStart, 'HH:mm')}-{format(item.adDateEnd, 'HH:mm')}</TableCell>
              </>}
              {screenSize > 900 && <>
                <TableCell>{item.anTrainingID}</TableCell>
              </>}
              
              <TableCell>{item.acName}</TableCell>
              {screenSize > 900 && <>
                <TableCell>{item.acSupplierName}</TableCell>
                <TableCell>{item.acTrainerName}</TableCell>
                <TableCell>{format(item.adDateStart, 'dd.MM.yyyy')} / {format(item.adDateEnd, 'dd.MM.yyyy')} {format(item.adDateStart, 'HH:mm')}-{format(item.adDateEnd, 'HH:mm')}</TableCell>
              </>}
              <TableCell>{item.acDescription}</TableCell>

              {auth.role.toLowerCase() == "manager" &&
                <TableCell style={{ textAlign: 'right', padding: 0 }}>
                  <Button
                    color="primary"
                    onClick={() => { openInPopup(item) }}>
                    <EditOutlinedIcon fontSize="small" />
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => { removeItem(item) }}>
                    <CloseIcon fontSize="small" />
                  </Button>
                </TableCell>
              }
            </TableRow>)
            )
          }
        </TableBody>
      </TblContainer>
      <TblPagination />
      <ResponsiveDialog
        title={formTitle}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <TrainingForm recordForEdit={recordForEdit}
          addOrEdit={addOrEdit} />
      </ResponsiveDialog>
      <ResponsiveDialogConfirm
        title={'Da li ste sigurni da želite da obrišete stavku?'}
        open={openPopupDel}
        setOpen={setOpenPopupDel}
        handleConfirm={deleteItem}
      />
    </div>
  )
}

export default Trainings
