import React, {useState} from 'react'
import {FormContainer, TextFieldElement, TextareaAutosizeElement, DateTimePickerElement, AutocompleteElement, useForm} from 'react-hook-form-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import locale from 'date-fns/locale/sr-Latn';
import { format } from 'date-fns';
import { getOptionsCoaches } from '../../services/coachService'
import { getOptionsSuppliers } from '../../services/supplierService'


const TrainingForm = (props) => {
    const { addOrEdit, recordForEdit } = props
    
    const [optionsCoach, setOptionsCoach] = useState([]);
    const [optionsSupplier, setOptionsSupplier] = useState([]);
    const [success, setSuccess] = useState(false)
    const [successMsg, setSuccessMsg] = useState('')
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const defaultValues = (recordForEdit != null ? {
        id: recordForEdit.anTrainingID,
        name: recordForEdit.acName,
        note: recordForEdit.acDescription,
        type: recordForEdit.acTrainingType,
        start: recordForEdit.adDateStart,
        end: recordForEdit.adDateEnd,
        coach: {label:recordForEdit.acTrainerName, id:recordForEdit.anTrainerID},
        supplier: {label:recordForEdit.acSupplierName, id:recordForEdit.anSupplierID}
    } : {
        id:0,
        name: '',
        note: '',
        type: '',
        
    })

    const formContext = useForm({
        defaultValues: defaultValues
    })

    

//   @anTrainingId int,@acTrainingName varchar(50),@acDescription varchar(1000),@acTrainingtype varchar(50),@adDateStart Smalldatetime,@adDateEnd smalldatetime,@anSupplierID int,@anTrainerID int

  const {handleSubmit} = formContext

  const saveCoach = (data) => {
    let submitdata = {...data }
    submitdata.start = format(new Date(data.start), 'yyyy-MM-dd HH:mm')
    submitdata.end = format(new Date(data.end), 'yyyy-MM-dd HH:mm')
    submitdata.coach = submitdata.coach.id
    submitdata.supplier = submitdata.supplier.id
    addOrEdit(submitdata, data, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading)
  }

  const onInputCoachChange = (e) => {
    getOptionsCoaches(setOptionsCoach,e.target.value)
  }
  const onInputSupplierChange = (e) => {
    getOptionsSuppliers(setOptionsSupplier,e.target.value)
  }
  const onInputCoachOpen =  () => {
    getOptionsCoaches(setOptionsCoach,'')
  }
  const onInputSupplierOpen = () => {
    getOptionsSuppliers(setOptionsSupplier,'')
  }
  

  return (
    <FormContainer
      formContext={formContext}
      handleSubmit={handleSubmit(saveCoach)}
    >
        {/* <TextFieldElement fullWidth margin="dense"  type="number" name="id"/> */}
        <TextFieldElement color="secondary" fullWidth margin="dense" sx={{display:'none'}} type="number" name="id"/>
        <TextFieldElement color="secondary" fullWidth margin="dense" name="name" label="Naziv" required  validation={{
            required: {
            value: true,
            message: ''
            }
            
        }}/>
        <TextareaAutosizeElement color="secondary" fullWidth margin="dense" name="note" label="Opis" resizeStyle="none" rows="4"/>
        <TextFieldElement color="secondary" fullWidth margin="dense" name="type" label="Tip" required validation={{
            required: {
            value: true,
            message: ''
            }
        }}/>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <DateTimePickerElement
                label="Početak"
                name="start"
                required
                textReadOnly
                color="secondary"
                inputProps={{
                    required: true,
                    fullWidth: true,
                    margin:"dense",
                    color:"secondary"
                }}
                validation={{
                    required: {
                    value: true,
                    message: ''
                    }
                }}
            />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
            <DateTimePickerElement
                label="Kraj"
                name="end"
                required
                textReadOnly
                color="secondary"
                inputProps={{
                    required: true,
                    fullWidth: true,
                    margin:"dense",
                    color:"secondary"
                }}
                validation={{
                    required: {
                    value: true,
                    message: ''
                    }
                }}
            />
        </LocalizationProvider>
        <AutocompleteElement
            name="coach"
            label="Trener"
            required
            color="secondary"
            textFieldProps={{
                color:"secondary",
                required: true,
                fullWidth: true,
                margin:"dense",
                validation:{
                    required: {
                        value: true,
                        message: 'aaa'
                    }
                },
                onChange:(e)=>{onInputCoachChange(e)}
            }}
            autocompleteProps={{
                required:true,
                validation:{
                    required: {
                    value: true,
                    message: ''
                    }
                },
                onOpen:(e)=>{onInputCoachOpen()}
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={optionsCoach}
            // onInputChange={onInputCoachChange}
            
            />
            <AutocompleteElement
                
                name="supplier"
                label="Dobavljač"
                required
                
                autocompleteProps={{
                    required:true,
                    validation:{
                        required: {
                        value: true,
                        message: ''
                        }
                    },
                    onOpen:(e)=>{onInputSupplierOpen()}
                }}
                textFieldProps={{
                    color:"secondary",
                    fullWidth: true,
                    margin:"dense",
                    validation:{
                        required: {
                            value: true,
                            message: 'aaa'
                        }
                    },
                    onChange:(e)=>{onInputSupplierChange(e)}
                    
                    
                }}
                
                options={optionsSupplier}
        
            />
        
      
      <br />
      <LoadingButton loading={submitLoading} type={'submit'} color={'primary'}>
          Potvrdi
        </LoadingButton>
        <div className="mb-5"></div>
        {success && <div className="mb-5"><Alert severity="success">{successMsg}</Alert></div>}
        {error && <div className="mb-5"><Alert severity="error">{errorMsg}</Alert></div>}
    </FormContainer>
  )
}

export default TrainingForm

// options={[
//     {
//     id: 1,
//     label: 'First'
//     },
//     {
//     id: 2,
//     label: 'Second'
//     },
//     {
//     id: 3,
//     label: 'Third'
//     },
//     {
//     id: 4,
//     label: 'Four'
//     }
// ]}