import React, { useState } from 'react'
import {FormContainer, TextFieldElement, TimePickerElement, AutocompleteElement, useForm, DatePickerElement, TextareaAutosizeElement} from 'react-hook-form-mui';
import * as calendarService from "../../services/calendarService";
import * as typeService from "../../services/typeService";
import {getOptionsJob} from "../../services/employeeService";
import { getOptionsTrainings } from '../../services/trainingService'
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import locale from 'date-fns/locale/sr-Latn';
import { format } from 'date-fns';
import { useEffect } from 'preact/hooks';

const PlanForm = (props) => {

  const { addOrEdit, recordForEdit } = props

  
  const [optionsStore, setOptionsStore] = useState([])
  const [optionsJob, setOptionsJob] = useState([])
  const [optionsType, setOptionsType] = useState([])
  const [optionsSubType, setOptionsSubType] = useState([])
  const [optionsTraining, setOptionsTraining] = useState([])
  const [optionsEmployee, setOptionsEmployee] = useState([])
  const [showTraining, setShowTraining] = useState(recordForEdit.acEngagementType.toLowerCase() == 'trening'? true : false)
  const [success, setSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const defaultValues =  {
    engagementId: recordForEdit.anId,
    employee: {id:recordForEdit.acWorkerID, label:recordForEdit.acWorkerFullName},
    engagementType: {label:recordForEdit.acEngagementType},
    engagementSubType: (recordForEdit.acEngagementType2 == "" ? null : {label:recordForEdit.acEngagementType2}),
    store: {id:recordForEdit.acStore, label:recordForEdit.acStoreFullName},
    job: {label:recordForEdit.acJob},
    date: new Date(recordForEdit.adDateTimeStart),
    startTime: new Date(recordForEdit.adDateTimeStart),
    endTime: new Date(recordForEdit.adDateTimeEnd),
    training: recordForEdit.anTrainingID != null ? {id:recordForEdit.anTrainingID, label:recordForEdit.acTrainingName} : null,
    note: recordForEdit.acNote
    
  }

  // console.log('defvals',defaultValues)
  const formContext = useForm({
    defaultValues: defaultValues
  })


  const {handleSubmit, watch} = formContext
  const watchStoreName = watch('store')
  const engagementType = watch('engagementType')
  const datecur = watch('date')

    React.useEffect(() => {
      // console.log('watchShowAge',datecur)
    //   if(watchShowAge == null || watchShowAge == ''){
    //     console.log('prazan')
    //     setShowFull(true)
    //   }else{
    //     console.log('popunjen')
    //     setShowFull(false)
    //   }

    }, [datecur])

  
  const onInputStoreChange = (e) => {
    calendarService.getOptionsStore(setOptionsStore, e.target.value)
  }
  const onInputStoreOpen = (e) => {
    calendarService.getOptionsStore(setOptionsStore, '')
  }
  const onInputJobChange = (e) => {
    getOptionsJob(setOptionsJob, e.target.value)
  }
  const onInputJobOpen = (e) => {
    getOptionsJob(setOptionsJob, '')
  }
  const onInputEmployeeChange = (e) => {
    calendarService.getOptionsEmployee(setOptionsEmployee, e.target.value, (watchStoreName == null ? '' : watchStoreName.id))
  }
  const onInputEmployeeOpen = (e) => {
    calendarService.getOptionsEmployee(setOptionsEmployee, '', (watchStoreName == null ? '' : watchStoreName.id))
  }

  const onInputTrainingChange = (e) => {
    const date = format(datecur,'yyyy-MM-dd')
    getOptionsTrainings(setOptionsTraining,e.target.value, date)
  }
  const onInputTrainingOpen =  () => {
    const date = format(datecur,'yyyy-MM-dd')
    getOptionsTrainings(setOptionsTraining,'', date)
  }

  
  const onInputTypeChange = (e) => {
    typeService.getOptionsType(setOptionsType, e.target.value)
  }
  const onInputTypeOpen = (e) => {
    typeService.getOptionsType(setOptionsType, '')
  }

  
  const onInputSubTypeChange = (e) => {
    typeService.getOptionsSubType(setOptionsSubType, e.target.value, (engagementType == null ? '' : engagementType.label),'')
  }
  const onInputSubTypeOpen = (e) => {
    typeService.getOptionsSubType(setOptionsSubType, '', (engagementType == null ? '' : engagementType.label),'')
  }


  const confirmCheckInOut = (data) => {
    let submitdata = {...data }
    // console.log('submit', submitdata)
    submitdata.employee = submitdata.employee.id
    submitdata.engagementType = submitdata.engagementType.label
    if(submitdata.engagementSubType != null){
        submitdata.engagementType = submitdata.engagementSubType.label
    }
    submitdata.store = submitdata.store.id
    submitdata.job = submitdata.job.label
    submitdata.training = submitdata.training == null ? 0 :submitdata.training.id
    
    submitdata.date = format(new Date(submitdata.date),'yyyy-MM-dd')
    submitdata.endTime = parseInt(format(new Date(submitdata.endTime),'HH'))
    submitdata.startTime = parseInt(format(new Date(submitdata.startTime),'HH'))
    // console.log(submitdata)
    addOrEdit(submitdata, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading)
  }
  
  return (
    <FormContainer
      formContext={formContext}
      handleSubmit={handleSubmit(confirmCheckInOut)}
    >
        
      
      <AutocompleteElement
            name="employee"
            label="Zaposleni"
            required
            textFieldProps={{
                required: true,
                fullWidth: true,
                margin:"dense",
                validation:{
                    required: {
                        value: true,
                        message: 'aaa'
                    }
                },
                color:"secondary",
                onChange:(e)=>{onInputEmployeeChange(e)}
            }}
            autocompleteProps={{
                required:true,
                validation:{
                    required: {
                    value: true,
                    message: ''
                    }
                },
                onOpen:(e)=>{onInputEmployeeOpen()}
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={optionsEmployee}
            // onInputChange={onInputCoachChange}
            
            />
        
      <AutocompleteElement
            name="store"
            label="Radnja"
            required
            textFieldProps={{
                required: true,
                fullWidth: true,
                margin:"dense",
                color:"secondary",
                validation:{
                    required: {
                        value: true,
                        message: 'aaa'
                    }
                },
                onChange:(e)=>{onInputStoreChange(e)}
            }}
            autocompleteProps={{
                required:true,
                validation:{
                    required: {
                    value: true,
                    message: ''
                    }
                },
                onOpen:(e)=>{onInputStoreOpen()}
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={optionsStore}
            
            
            />
        <AutocompleteElement
            name="job"
            label="Radno mesto"
            required
            color="secondary"
            textFieldProps={{
                color:"secondary",
                required: true,
                fullWidth: true,
                margin:"dense",
                validation:{
                    required: {
                        value: true,
                        message: 'aaa'
                    }
                },
                onChange:(e)=>{onInputJobChange(e)}
            }}
            autocompleteProps={{
                required:true,
                validation:{
                    required: {
                    value: true,
                    message: ''
                    }
                },
                onOpen:(e)=>{onInputJobOpen()}
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={optionsJob}
            // onInputChange={onInputCoachChange}
            
      />
      <AutocompleteElement
            name="engagementType"
            label="Tip angažovanja"
            required
            color="secondary"
            textFieldProps={{
                color:"secondary",
                required: true,
                fullWidth: true,
                margin:"dense",
                validation:{
                    required: {
                        value: true,
                        message: 'aaa'
                    }
                },
                onChange:(e)=>{onInputTypeChange(e)}
                
            }}
            autocompleteProps={{
                required:true,
                validation:{
                    required: {
                    value: true,
                    message: ''
                    }
                },
                onOpen:(e)=>{onInputTypeOpen()},
                onChange:(e, value)=>{
                    if(value != null){
                        if(value.label.toLowerCase() == 'trening' ){
                            setShowTraining(true)
                        }else{
                            setShowTraining(false)
                        }
                    }else{
                        setShowTraining(false)
                    }
                }
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={optionsType}
            
            // onInputChange={onInputCoachChange}
            
      />
      <AutocompleteElement
            name="engagementSubType"
            label="Podtip angažovanja"
            
            color="secondary"
            textFieldProps={{
                color:"secondary",
                fullWidth: true,
                margin:"dense",
                onChange:(e)=>{onInputSubTypeChange(e)}
                
            }}
            autocompleteProps={{
                
                validation:{
                    required: {
                    value: true,
                    message: ''
                    }
                },
                onOpen:(e)=>{onInputSubTypeOpen()}
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={optionsSubType}
        
            // onInputChange={onInputCoachChange}
            
      />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <DatePickerElement
            fullWidth
            label="Datum"
            name="date"
            required
            validation={{
                required: 'Obavezno je odabrati vreme!'
            }}
            inputProps={{
              required: true,
              fullWidth: true,
              margin:"dense",
              color:"secondary"
          }}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <TimePickerElement
            fullWidth
            label="Početak"
            name="startTime"
            required
            validation={{
                required: 'Obavezno je odabrati vreme!'
            }}
            inputProps={{
              required: true,
              fullWidth: true,
              margin:"dense",
              color:"secondary"
          }}
        />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <TimePickerElement
            fullWidth
            label="Kraj"
            name="endTime"
            required
            validation={{
                required: 'Obavezno je odabrati vreme!'
            }}
            inputProps={{
              required: true,
              fullWidth: true,
              margin:"dense",
              color:"secondary"
          }}
        />
        </LocalizationProvider>
        {showTraining && <AutocompleteElement
            name="training"
            label="Trening"
            color="secondary"
            textFieldProps={{
                color:"secondary",
                required: true,
                fullWidth: true,
                margin:"dense",
                validation:{
                    required: {
                        value: true,
                        message: 'aaa'
                    }
                },
                onChange:(e)=>{onInputTrainingChange(e)}
            }}
            autocompleteProps={{
                required:true,
                validation:{
                    required: {
                    value: true,
                    message: ''
                    }
                },
                onOpen:(e)=>{onInputTrainingOpen()}
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={optionsTraining}
            />
        }
        <TextareaAutosizeElement color="secondary" fullWidth margin="dense" name="note" label="Napomena" resizeStyle="none" rows="4"/>
      <br />
      <LoadingButton loading={submitLoading}  type={'submit'} color={'primary'}>
        Potvrdi
      </LoadingButton>
      {success && <div className="mb-5"><Alert severity="success">{successMsg}</Alert></div>}
      {error && <div className="mb-5"><Alert severity="error">{errorMsg}</Alert></div>}
    </FormContainer>
  )
}

export default PlanForm