import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import Login from './components/Login';
import { ContextProvider } from './contexts/ContextProvider'
import { AuthProvider } from './contexts/AuthProvider'
import { HashRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme  } from '@mui/material/styles';
import RequireAuth from './components/RequireAuth';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ec2028',
        },
        secondary: {
            main: '#3c3c3c',
        },
    },
  });


ReactDOM.render(<ContextProvider>
    <AuthProvider>
    <HashRouter>
    <ThemeProvider theme={theme}>
    <Routes>

         <Route path="/login" element={<Login />} />
         <Route element={<RequireAuth />}>
          <Route path="/*" element={<App />} />
          </Route>
          
        </Routes>
        </ThemeProvider>
        </HashRouter>
    </AuthProvider>
</ContextProvider>, document.getElementById('root'));
