import React, { useState, useEffect, useRef } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction"
import timeGrid from '@fullcalendar/timegrid'
import srLocale from '@fullcalendar/core/locales/sr';
import list from '@fullcalendar/list'
import { Header } from '../components'
import * as calendarService from "../services/calendarService";
import * as vacationService from "../services/vacationService";

import { format } from 'date-fns';
import ResponsiveDialog from "../components/ResponsiveDialog";
import CheckInOutForm from '../components/Forms/CheckInOutForm'
import PlanForm from '../components/Forms/PlanForm'
import VacationForm from '../components/Forms/VacationForm'
import useAuth from '../hooks/useAuth';
import { useStateContext } from '../contexts/ContextProvider';


import CalendarFilters from './CalendarFilters'
import { Divider } from '@mui/material'

const Calendar = () => {
    const { screenSize,  } = useStateContext();
    const calendar = useRef();
  
    // useEffect(()=>{
    //   // console.log(calendar)
    // },[calendar])
    const { auth } = useAuth();
    // console.log('auth',auth)
    const [employee, setEmployee] = useState((auth.role.toLowerCase() == "user"  ? auth.user : ""))
    const [jobFilt, setJobFilt] = useState('')
    const [engagementType, setEngagementType] = useState("")
    const [engagementSubType, setEngagementSubType] = useState("")
    const [store, setStore] = useState((auth.role.toLowerCase() != "manager"  ? auth.storeId : 0))
    const [storeName, setStoreName] = useState((auth.role.toLowerCase() != "manager"  ? auth.store : ""))
    
    const [openPopupCheckInOut,setOpenPopupCheckInOut] = useState(false)
    const [openPopupPlan,setOpenPopupPlan] = useState(false)
    const [openPopupVacation,setOpenPopupVacation] = useState(false)
    
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [toolbar,setToolbar] =  useState(window.innerWidth <= 900 ? {} : {center: 'dayGridMonth,dayGridWeek,dayGridDay'})
    const [initialView,setInitialView] = useState(window.innerWidth <= 900 ? "listWeek"  :  "dayGridWeek" )
    
    const handleDateClick = (arg) => { // bind with an arrow function
        alert(arg.dateStr)
    }
    const handleEventClick = (arg) => { // bind with an arrow function
      
      setRecordForEdit(arg.event.extendedProps)
      setOpenPopupPlan(true)
    }
    const eventClickFn = auth.role.toLowerCase() == "manager" ? handleEventClick : () =>{}
    
    const getEvents = (info, successCallback) => {
      
      calendarService.getAllCalendarEvents(info, successCallback, employee, store, engagementType, engagementSubType, jobFilt)
    }

    const CheckInOut = (data, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading) => {
      
      setSuccess(false)
      setError(false)
      calendarService.saveCheckInOut(data, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading, setOpenPopupCheckInOut)
      // setOpenPopupCheckInOut(false)
    }

    const updatePlan = (data, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading) => {
      
      setSuccess(false)
      setError(false)
      calendarService.insertUpdatePlan(data, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading, setOpenPopupPlan)
      // setOpenPopupCheckInOut(false)
    }

    
    const confirmVacationReq = (data) => {
      
      vacationService.insertUpdateVacation(data)
      setOpenPopupVacation(false)
    }

    
function renderEventContent(eventInfo) {
  // console.log('eventInfo',eventInfo)
  let calendarApi = calendar.current.getApi()
  // console.log('view',calendarApi.view.type)
  let showdetails = calendarApi.view.type == 'dayGridWeek' || calendarApi.view.type == 'dayGridDay' || calendarApi.view.type == 'listWeek'  ? true : false
  return (
    <>
      <div style={{padding:5,  width: '100%', whiteSpace: 'normal'}}>
        {eventInfo.timeText != '' &&
          <>
          <div style={{display:'flex',flexDirection:'row', alignItems:'center'}}>
            <div className="fc-daygrid-event-dot" style={{borderColor: 'rgb(236, 32, 40)'}}></div>
            <div className="fc-event-time">{eventInfo.event.extendedProps.adDateTimeStart.substring(11,16)} - {eventInfo.event.extendedProps.adDateTimeEnd.substring(11,16)}</div>
          </div>
          
          </>          
        }
        {
          eventInfo.event.extendedProps.isFullTime == 1 && 
          <>
          { 
          // calendarApi.view.type == 'listWeek'
          auth.role.toLowerCase() == "user"
           &&
            <>
          {
            eventInfo.event.extendedProps.anTrainingID == null &&
            <div className="">{eventInfo.event.extendedProps.acEngagementType}</div>
          }
          {
            eventInfo.event.extendedProps.anTrainingID != null &&
            <>
            <div className="">{eventInfo.event.extendedProps.acTrainingName}</div>
            <div className="">{eventInfo.event.extendedProps.acTrainingDescription}</div>
            </>
          }
            </>
          }
          { 
          // calendarApi.view.type != 'listWeek' 
          auth.role.toLowerCase() != "user"
          &&
            <>
            <div className="">{eventInfo.event.extendedProps.acEngagementType}</div>
            {
              eventInfo.event.extendedProps.anTrainingID != null &&
              <>
              <div className="">{eventInfo.event.extendedProps.acTrainingName}</div>
              </>
            }
            </>
          }
          </>
        }
        
        {employee != "" && eventInfo.event.extendedProps.isFullTime == 0 &&
          <div className="">{eventInfo.event.extendedProps.acStore}</div>
        }
        {store != "" && employee == "" &&
          <div className="">{eventInfo.event.extendedProps.acWorkerFullName}</div>
        }
        {store== "" && employee == "" &&
        <>
          <div className="">{eventInfo.event.extendedProps.acStore}</div>
          <div className="">{eventInfo.event.extendedProps.acWorkerFullName}</div>
          </>
        }
        {auth.role.toLowerCase() == "manager" && showdetails &&
        <>
          <div className="">{eventInfo.event.extendedProps.acEngagementType2}</div>
          <div className="">{eventInfo.event.extendedProps.acNote}</div>
          </>
        }
        
      </div>
      
     
    </>
  )
}

{/* <a tabindex="0" class="fc-event fc-event-draggable fc-event-resizable fc-event-start fc-event-end fc-event-past fc-daygrid-event fc-daygrid-dot-event"><div class="fc-daygrid-event-dot" style="border-color: rgb(236, 32, 40);"></div><div class="fc-event-time">00:00</div><div class="fc-event-title">DELTA NIŠ
Sanja Stojanović</div></a> */}


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="App" title="Kalendar" />
      <CalendarFilters employee={employee}
        setEmployee={setEmployee}
        store={store}
        setStore={setStore}
        storeName={storeName}
        setStoreName={setStoreName}
        engagementType={engagementType}
        setEngagementType={setEngagementType}
        setEngagementSubType={setEngagementSubType}
        setOpenPopupCheckInOut={setOpenPopupCheckInOut}
        jobFilt={jobFilt}
        setJobFilt={setJobFilt}
        
      />
      <div className="mt-5"></div>
      <FullCalendar 
            ref={calendar}
            // plugins={[ dayGridPlugin ]}
            initialView={initialView}
            plugins={[ dayGridPlugin, timeGrid, interactionPlugin, list ]}
            // dateClick={handleDateClick}
            eventClick={eventClickFn}
            locales ={ [ srLocale ]}
            locale= 'sr'
            // events={[
            //     { title: 'event 1', date: '2023-01-10' },
            //     { title: 'event 2', start: '2023-01-13T14:30:00', end: '2023-01-13T17:30:00',color: '#ec2028',textColor:'black'  }
            //   ]}
            events={(info, successCallback, failureCallback) => { getEvents(info, successCallback, failureCallback)}}
            eventTimeFormat={{ // like '14:30:00'
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
            hour12: false
            }}
            views= {{
              dayGridMonth: { // name of view
                dayHeaderContent : (info) => {
                  let dayOfWeek = info.dow
                  let dayOfWeekStr
                  switch(dayOfWeek){
                    case 0:
                      dayOfWeekStr='Nedelja'
                      break;
                    case 1:
                      dayOfWeekStr='Ponedeljak'
                      break;
                    case 2:
                      dayOfWeekStr='Utorak'
                      break;
                    case 3:
                      dayOfWeekStr='Sreda'
                      break;
                    case 4:
                      dayOfWeekStr='Četvrtak'
                      break;
                    case 5:
                      dayOfWeekStr='Petak'
                      break;
                    case 6:
                      dayOfWeekStr='Subota'
                      break;
                  }
                  return dayOfWeekStr
                },
                titleFormat: (info) => {
                  
                  let month = info.date.month+1
                  let monthName
                  switch(month){
                    case 1:
                      monthName='Januar'
                      break;
                    case 2:
                      monthName='Februar'
                      break;
                    case 3:
                      monthName='Mart'
                      break;
                    case 4:
                      monthName='April'
                      break;
                    case 5:
                      monthName='Maj'
                      break;
                    case 6:
                      monthName='Jun'
                      break;
                    case 7:
                      monthName='Jul'
                      break;
                    case 8:
                      monthName='Avgust'
                      break;
                    case 9:
                      monthName='Septembar'
                      break;
                    case 10:
                      monthName='Oktobar'
                      break;
                    case 11:
                      monthName='Novembar'
                      break;
                    case 12:
                      monthName='Decembar'
                      break;
                  }
                  return `${monthName} ${info.date.year}.`
                },
                dayCellContent: (arg) => {
                  let monthSt = arg.date.getMonth()+1
                  let monthStName
                  switch(monthSt){
                    case 1:
                      monthStName='Jan'
                      break;
                    case 2:
                      monthStName='Feb'
                      break;
                    case 3:
                      monthStName='Mar'
                      break;
                    case 4:
                      monthStName='Apr'
                      break;
                    case 5:
                      monthStName='Maj'
                      break;
                    case 6:
                      monthStName='Jun'
                      break;
                    case 7:
                      monthStName='Jul'
                      break;
                    case 8:
                      monthStName='Avg'
                      break;
                    case 9:
                      monthStName='Sep'
                      break;
                    case 10:
                      monthStName='Okt'
                      break;
                    case 11:
                      monthStName='Nov'
                      break;
                    case 12:
                      monthStName='Dec'
                      break;
                  }
                  
                  return arg.dayNumberText+'. '+monthStName
                }
                
              },
              dayGridWeek:{
                dayHeaderContent : (info) => {
                  
                  let dayOfWeek = info.dow
                  let dateText = format(info.date, 'dd.M')
                  let dayOfWeekStr
                  switch(dayOfWeek){
                    case 0:
                      dayOfWeekStr='Ned.'
                      break;
                    case 1:
                      dayOfWeekStr='Pon.'
                      break;
                    case 2:
                      dayOfWeekStr='Uto.'
                      break;
                    case 3:
                      dayOfWeekStr='Sre.'
                      break;
                    case 4:
                      dayOfWeekStr='Čet.'
                      break;
                    case 5:
                      dayOfWeekStr='Pet.'
                      break;
                    case 6:
                      dayOfWeekStr='Sub.'
                      break;
                  }
                  return dayOfWeekStr+ ' '+dateText
                },
                titleFormat: (info) => {
                  
                  let monthSt = info.start.month+1
                  let monthStName
                  switch(monthSt){
                    case 1:
                      monthStName='Jan'
                      break;
                    case 2:
                      monthStName='Feb'
                      break;
                    case 3:
                      monthStName='Mar'
                      break;
                    case 4:
                      monthStName='Apr'
                      break;
                    case 5:
                      monthStName='Maj'
                      break;
                    case 6:
                      monthStName='Jun'
                      break;
                    case 7:
                      monthStName='Jul'
                      break;
                    case 8:
                      monthStName='Avg'
                      break;
                    case 9:
                      monthStName='Sep'
                      break;
                    case 10:
                      monthStName='Okt'
                      break;
                    case 11:
                      monthStName='Nov'
                      break;
                    case 12:
                      monthStName='Dec'
                      break;
                  }
                  let monthEnd = info.end.month+1
                  let monthEndName
                  switch(monthEnd){
                    case 1:
                      monthEndName='Jan'
                      break;
                    case 2:
                      monthEndName='Feb'
                      break;
                    case 3:
                      monthEndName='Mar'
                      break;
                    case 4:
                      monthEndName='Apr'
                      break;
                    case 5:
                      monthEndName='Maj'
                      break;
                    case 6:
                      monthEndName='Jun'
                      break;
                    case 7:
                      monthEndName='Jul'
                      break;
                    case 8:
                      monthEndName='Avg'
                      break;
                    case 9:
                      monthEndName='Sep'
                      break;
                    case 10:
                      monthEndName='Okt'
                      break;
                    case 11:
                      monthEndName='Nov'
                      break;
                    case 12:
                      monthEndName='Dec'
                      break;
                  }
                  if(monthEndName == monthStName){
                    return `${info.start.day} - ${info.end.day}. ${monthEndName} ${info.date.year}.`
                  }else{
                    return `${info.start.day}. ${monthStName} - ${info.end.day}. ${monthEndName} ${info.date.year}.`
                  }
                  
                }
                
              },
              listWeek:{
                dayHeaderContent : (info) => {
                  
                  let dayOfWeek = info.dow
                  let dateText = format(info.date, 'dd.M')
                  let dayOfWeekStr
                  switch(dayOfWeek){
                    case 0:
                      dayOfWeekStr='Ned.'
                      break;
                    case 1:
                      dayOfWeekStr='Pon.'
                      break;
                    case 2:
                      dayOfWeekStr='Uto.'
                      break;
                    case 3:
                      dayOfWeekStr='Sre.'
                      break;
                    case 4:
                      dayOfWeekStr='Čet.'
                      break;
                    case 5:
                      dayOfWeekStr='Pet.'
                      break;
                    case 6:
                      dayOfWeekStr='Sub.'
                      break;
                  }
                  return dayOfWeekStr+ ' '+dateText
                },
                titleFormat: (info) => {
                  
                  let monthSt = info.start.month+1
                  let monthStName
                  switch(monthSt){
                    case 1:
                      monthStName='Jan'
                      break;
                    case 2:
                      monthStName='Feb'
                      break;
                    case 3:
                      monthStName='Mar'
                      break;
                    case 4:
                      monthStName='Apr'
                      break;
                    case 5:
                      monthStName='Maj'
                      break;
                    case 6:
                      monthStName='Jun'
                      break;
                    case 7:
                      monthStName='Jul'
                      break;
                    case 8:
                      monthStName='Avg'
                      break;
                    case 9:
                      monthStName='Sep'
                      break;
                    case 10:
                      monthStName='Okt'
                      break;
                    case 11:
                      monthStName='Nov'
                      break;
                    case 12:
                      monthStName='Dec'
                      break;
                  }
                  let monthEnd = info.end.month+1
                  let monthEndName
                  switch(monthEnd){
                    case 1:
                      monthEndName='Jan'
                      break;
                    case 2:
                      monthEndName='Feb'
                      break;
                    case 3:
                      monthEndName='Mar'
                      break;
                    case 4:
                      monthEndName='Apr'
                      break;
                    case 5:
                      monthEndName='Maj'
                      break;
                    case 6:
                      monthEndName='Jun'
                      break;
                    case 7:
                      monthEndName='Jul'
                      break;
                    case 8:
                      monthEndName='Avg'
                      break;
                    case 9:
                      monthEndName='Sep'
                      break;
                    case 10:
                      monthEndName='Okt'
                      break;
                    case 11:
                      monthEndName='Nov'
                      break;
                    case 12:
                      monthEndName='Dec'
                      break;
                  }
                  if(monthEndName == monthStName){
                    return `${info.start.day} - ${info.end.day}. ${monthEndName} ${info.date.year}.`
                  }else{
                    return `${info.start.day}. ${monthStName} - ${info.end.day}. ${monthEndName} ${info.date.year}.`
                  }
                  
                }
                
              },
              dayGridDay:{
                dayHeaderContent : (info) => {
                  let dayOfWeek = info.dow
                  let dayOfWeekStr
                  switch(dayOfWeek){
                    case 0:
                      dayOfWeekStr='Nedelja'
                      break;
                    case 1:
                      dayOfWeekStr='Ponedeljak'
                      break;
                    case 2:
                      dayOfWeekStr='Utorak'
                      break;
                    case 3:
                      dayOfWeekStr='Sreda'
                      break;
                    case 4:
                      dayOfWeekStr='Četvrtak'
                      break;
                    case 5:
                      dayOfWeekStr='Petak'
                      break;
                    case 6:
                      dayOfWeekStr='Subota'
                      break;
                  }
                  return dayOfWeekStr
                },
                titleFormat: (info) => {
                  
                  let month = info.date.month+1
                  let monthName
                  switch(month){
                    case 1:
                      monthName='Januar'
                      break;
                    case 2:
                      monthName='Februar'
                      break;
                    case 3:
                      monthName='Mart'
                      break;
                    case 4:
                      monthName='April'
                      break;
                    case 5:
                      monthName='Maj'
                      break;
                    case 6:
                      monthName='Jun'
                      break;
                    case 7:
                      monthName='Jul'
                      break;
                    case 8:
                      monthName='Avgust'
                      break;
                    case 9:
                      monthName='Septembar'
                      break;
                    case 10:
                      monthName='Oktobar'
                      break;
                    case 11:
                      monthName='Novembar'
                      break;
                    case 12:
                      monthName='Decembar'
                      break;
                  }
                  return `${info.date.day}. ${monthName} ${info.date.year}.`
                }
                
              }
            }}
            editable= {true}
            eventColor={'#ec2028'}
            headerToolbar= { toolbar }
            eventContent={renderEventContent}
            height={650}
            // contentHeight= { ()=>{
              
            //   if(screenSize <= 900) {
            //         return 1000
            //     } else {
            //         return 1000
            //     }
            //   }
            // }
            // showNonCurrentDates={false}
        />
        <ResponsiveDialog
            title="Prijava/Odjava na radno mesto"
            openPopup={openPopupCheckInOut}
            setOpenPopup={setOpenPopupCheckInOut}
        >
          <CheckInOutForm 
                addOrEdit={CheckInOut} />
        </ResponsiveDialog>
        <ResponsiveDialog
            title="Izmena plana"
            openPopup={openPopupPlan}
            setOpenPopup={setOpenPopupPlan}
        >
          <PlanForm 
                addOrEdit={updatePlan} recordForEdit={recordForEdit}/>
        </ResponsiveDialog>
        {/* <ResponsiveDialog
            title="Zahtev za Godišnji odmor"
            openPopup={openPopupVacation}
            setOpenPopup={setOpenPopupVacation}
        >
          <VacationForm 
                addOrEdit={confirmVacationReq} />
        </ResponsiveDialog> */}
    </div>
  )
  
}

export default Calendar