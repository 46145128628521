import React, {useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navbar, Sidebar } from './components';
import { PlanInsert, Coaches, Calendar, Employees, Trainings, Suppliers, CheckInOut, Vacations, Logout, Types, RemovePlan} from './pages';
import './App.css';

import { useStateContext } from './contexts/ContextProvider';

const App = () => {
    
    
    const { activeMenu } = useStateContext();

    
  return (
    <>
    
    
    <div>
            <div className='flex relative dark:bg-main-dark-bg'>
              
                {activeMenu ? (
                    <div className='w-72 fixed sidebar 
                    dark:bg-secondary-dark-bg
                    bg-white'>
                        <Sidebar />
                    </div>
                ) : (
                    <div className='w-0 
                    dark:bg-secondary-dark-bg'>
                        <Sidebar />
                    </div>
                )}
                <div className={
                    `dark:bg-main-bg bg-main-bg min-h-screen w-full ${ activeMenu ? 'md:ml-72' : 'flex-2'}`
                }>
                    <div className='fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full'><Navbar /></div>
                
                <div>
                    <Routes>
                        {/* Dashboards */}
                        <Route path="/" element={<Calendar />} />
                        
                        {/* Pages */}
                        <Route path="/prijave" element={<CheckInOut />} />
                        <Route path="/odsustvo" element={<Vacations />} />

                        <Route path="/treneri" element={<Coaches />} />
                        <Route path="/zaposleni" element={<Employees />} />
                        <Route path="/treninzi" element={<Trainings />} />
                        <Route path="/dobavljaci" element={<Suppliers />} />
                        <Route path="/tipovipodtipovi" element={<Types />} />
                        <Route path="/brisanjeplana" element={<RemovePlan />} />
                        <Route path="/unosplana" element={<PlanInsert />} />

                        {/* Apps */}
                        <Route path="/kalendar" element={<Calendar />} />

                        <Route path="/odjava" element={<Logout />} />
                    </Routes>
                </div>
                </div>
            </div>
            
    </div>
    
    </>
  )
}

export default App