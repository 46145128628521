import { url } from '../data/dummy';

export function deleteType(data, setRecords) {
    
    fetch(url+'/types.php', { // Your POST endpoint
        method: 'DELETE',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            if(success[0].isOk == 0){
                alert(success[0].acMessage)
            }else{
                
                setRecords(prev => {
                
                    return prev.filter(x => x.anAttendanceID != data.anAttendanceID)
                })
            }
        } // Handle the success response object // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}


export function insertUpdateType(data, setRecords, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading,setRecordForEdit, setOpenPopup) {
    
    console.log('snimanje',data)
    // return false;
    setSubmitLoading(true)
    fetch(url+'/types.php', { // Your POST endpoint
        method: 'POST',
        body:  JSON.stringify(data)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => {
            if(success[0].isOk == 0){
                setErrorMsg(success[0].acMessage)
                setError(true)
                
            }else{
                
                setSuccessMsg(success[0].acMessage)
                setSuccess(true)
                
                if(success[0].TipAkcije.toLowerCase() == 'insert'){
                    setRecords(prev => {
                        return [...prev,{
                            anAttendanceID: success[0].anId,
                            acName: data.name,
                            acParentName: data.parent,
                            acdescription:data.note,
                            isFullTime:parseInt(data.fullTime)
                            }]
                    })
                }else{
                    setRecords(prev => {
                        let newrec = prev.map(item=>item)
                        newrec.forEach(item => {
                            if(item.anAttendanceID == data.id){
                                item.acName= data.name
                                item.acParentName = data.parent
                                item.acdescription =data.note
                                item.isFullTime = parseInt(data.fullTime)
                            }
                        })
                        return newrec
                    })
                }
                
                setTimeout(() => {
                    setRecordForEdit(null)
                    setOpenPopup(false)
                },2000)
            }
            setSubmitLoading(false)
        } // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}

export async function getAllTypes(setRecords) {
    
    fetch(url+'/types.php?type=list', { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => setRecords(success) // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}

export async function getOptionsType(setRecords, value) {
    
    fetch(url+'/types.php?type=optionstype&value='+value, { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => setRecords(success) // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}


export async function getOptionsSubType(setRecords, value, parent) {
    
    fetch(url+'/types.php?type=optionssubtype&value='+value+'&parent='+parent, { // Your POST endpoint
        method: 'GET',
        // body:  JSON.stringify(reqBody)// This is your file object
    }).then(
        response => response.json() // if the response is a JSON object
    ).then(
        success => setRecords(success) // Handle the success response object
    ).catch(
        error => console.log(error) // Handle the error response object
    );
}
