import React, {useState} from 'react'
import useTable from './useTable'
import { Button, TextField, TableBody, TableRow, TableCell, Autocomplete, FormControlLabel, FormGroup, Checkbox, CircularProgress, Grid, Alert } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import locale from 'date-fns/locale/sr-Latn';
import { format, isBefore, isAfter } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';

const Errortable = ({recordsprop}) => {
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
    const [records, setRecords] = useState(recordsprop)
    const headCells = [
        { id: 'acWorkerID', label: 'Zaposleni' },
        { id: 'date', label: 'Datum' },
        { id: 'acEngagementType', label: 'Tip' },
        { id: 'acNote', label: 'Greška'}
      ]
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        filteredRecords
    } = useTable(records, headCells, filterFn);
  return (
    
    <>
    <Alert severity="error">Ispravite sledeće podatke, pa ponovo uvezite plan!</Alert>
    <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map((item, i) =>{
                                
                                return (<TableRow key={i}>
                                    <TableCell>{item.acWorkerID}</TableCell>
                                    <TableCell>{format(new Date(item.adDateStart),'dd.MM.yyyy')} {format(new Date(item.adDateStart),'HH:mm')}-{format(new Date(item.adDateEnd),'HH:mm')}</TableCell>
                                    <TableCell>{item.acEngagementType}</TableCell>
                                    <TableCell style={{textAlign:"right"}}>{item.acNote}</TableCell>
                                </TableRow>)
                            
                                })
                        }
                    </TableBody>
                </TblContainer>
                <TblPagination />
                </>
  )
}

export default Errortable