import React, {useState} from 'react'
import {FormContainer, TextFieldElement, AutocompleteElement, useForm, PasswordElement} from 'react-hook-form-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';

const UserForm = (props) => {
  const { type, addOrEdit, recordForEdit } = props
  const [success, setSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const defaultValues = (recordForEdit != null ? {
    type:type,
    userId: recordForEdit.acUserLogIn,
    workerId: recordForEdit.acWorkerID,
    userType: recordForEdit.acUserTYpe,
    pass: '',
    pin: '',
    
  } : 
  {
    type:'INSERT',
    userId: '',
    workerId: '',
    userType: '',
    pass: '',
    pin: '',
  })

  
  
  const formContext = useForm({
    defaultValues: defaultValues
  })


  const {handleSubmit} = formContext

  const saveUser = (data) => {
    let submitdata = {...data }
    submitdata.userType = submitdata.userType.label
    addOrEdit(submitdata, setSuccess, setSuccessMsg, setError, setErrorMsg, setSubmitLoading)
  }
  let sx = {}
  if(type == 'INSERT'){
    sx = {}
  }else {
    sx = {display: 'none'}
  }
  return (
    <FormContainer
      formContext={formContext}
      handleSubmit={handleSubmit(saveUser)}
    >
      <TextFieldElement fullWidth margin="dense" name="type" label="Tip" sx={{display:'none'}}/>
      <TextFieldElement fullWidth margin="dense" name="userId" sx={sx} label="Korisničko ime (email)" type={'email'} required validation={{
              required: {
                value: true,
                message: ''
              }
            }}/>
      <TextFieldElement fullWidth margin="dense" name="workerId" sx={{display:'none'}} label="ID zaposlenog" required validation={{
              required: {
                value: true,
                message: ''
              }
            }}/>
            
      
      <AutocompleteElement
            
            name="userType"
            label="Tip korisnika"
            required
            color="secondary"
            textFieldProps={{
                color:"secondary",
                required: true,
                fullWidth: true,
                margin:"dense",
                validation:{
                    required: {
                        value: true,
                        message: 'aaa'
                    }
                },
                sx:sx
            }}
            autocompleteProps={{
                required:true,
                validation:{
                    required: {
                    value: true,
                    message: ''
                    }
                }
            }}
            validation={{
                required: {
                value: true,
                message: ''
                }
            }}
            options={[
                {label:'USER'},
                {label:'ADMIN'},
                {label:'MANAGER'},
            ]}
            
      />
      <PasswordElement
            color="secondary"
            fullWidth
            margin={'dense'}
            label={'Lozinka'}
            // required
            name={'pass'}
        />
    <TextFieldElement color="secondary" fullWidth margin="dense" type="number" label="PIN"
    //  required
      name="pin"/>
      <br />
      <LoadingButton loading={submitLoading} type={'submit'} color={'primary'}>
        Potvrdi
      </LoadingButton>
      {success && <div className="mb-5"><Alert severity="success">{successMsg}</Alert></div>}
      {error && <div className="mb-5"><Alert severity="error">{errorMsg}</Alert></div>}
    </FormContainer>
  )
}

export default UserForm